/*------------------------------------------------------------------
[Master Stylesheet]
Project:	Gaea
Version:	1.0
Last change:	19/06/15
Author:	imithemes 
-------------------------------------------------------------------*/
/* Stylesheets and Google fonts import */
@import url("animations.css");
@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700);

/*------------------------------------------------------------------
[Table of contents]

0. CSS Reset
1. Basic Styles
2. Typography
3. Margin Bottom Sets
4. Padding Sets
5. Spacers Sets
6. Site Structure & Appearance
	6.1. Main Navigation
	6.2. Slider Revolution Custom Caption Styling
	6.3. Widgets Styling
	6.4. Counter Shortcode
	6.5. Parallax Backgrounds
7. Responsive Media Queries
-------------------------------------------------------------------*/

/*-------------------------------------------------------------------------------------------*/
/* 0. RESET */
/*-------------------------------------------------------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

iframe {
  border: 0;
}

/* ==================================================
   1. Basic Styles
================================================== */

html,
body {
  height: 100%;
}

html,
hmtl a {
  width: 100%;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a {
  color: #555555;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0.2s;
  -moz-transition: all 0.3s ease 0.2s;
  -ms-transition: all 0.3s ease 0.2s;
  -o-transition: all 0.3s ease 0.2s;
  transition: all 0.3s ease 0.1s;
}

a:hover {
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  outline: 0;
  color: #666666;
}

a img {
  border: none;
}

a>img {
  vertical-align: middle;
}

.gray-bg {
  background: #e8e8e8;
}

.gray-text {
  color: #bababa;
}

.lgray-bg {
  background: #fafafa;
}

.dgray-bg {
  background: #333333;
}

img {
  max-width: 100%;
}

img,
object,
embed,
audio,
video {
  max-width: 100%;
}

img {
  height: auto;
}

strong,
.strong {
  font-weight: 700;
}

p {
  margin: 0 0 20px;
}

i,
em,
.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.align-left {
  float: left;
  margin: 0 25px 0 0px !important;
}

.align-right {
  float: right;
  margin: 0 0 0 25px !important;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.color-text {
  color: #ffffff;
}

.through {
  text-decoration: line-through;
}

.border-radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

/* ==================================================
   2. Typography
================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 18px 0;
  color: #333333;
  font-weight: 400;
  line-height: 1.5em;
  font-family: "Roboto Slab", sans-serif;
  font-display: swap;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h2 small {
  display: block;
  letter-spacing: 0.1em;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  text-transform: uppercase;
  font-size: 45%;
}

h3 {
  font-size: 18px;
}

h3.block-title {
  text-transform: uppercase;
  font-size: 16px;
}

h4 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-family: "Roboto", sans-serif;
  font-display: swap;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

h1.short,
h2.short,
h3.short,
h4.short,
h5.short,
h6.short,
p.short {
  margin-bottom: 5px;
}

h1.spaced,
h2.spaced,
h3.spaced,
h4.spaced,
h5.spaced,
h6.spaced {
  margin-top: 22px;
}

h1 a,
h2 a,
h3 a,
h5 a,
h6 a {
  color: #333333;
  text-decoration: none;
}

.huge {
  font-size: 10em;
}

blockquote {
  margin: 30px 0;
  padding: 0 0 0 30px;
  border-left: 3px solid;
}

blockquote p {
  margin-bottom: 0;
  white-space: normal;
  font-style: italic;
  font-size: 20px;
}

blockquote cite {
  color: #ffffff;
}

blockquote cite:before {
  content: "- ";
}

p {
  margin: 0 0 18px 0;
}

p.md {
  font-size: 16px;
  line-height: 22px;
}

p.lg {
  font-size: 30px;
  line-height: 1.2em;
}

p.lead {
  font-family: "Roboto", sans-serif;
  font-display: swap;
}

.big {
  font-size: 200%;
  line-height: 1.5em;
}

.thin {
  font-weight: 300;
}

p.drop-caps:first-child:first-letter {
  float: left;
  margin-top: 5px;
  margin-right: 5px;
  padding: 4px;
  font-size: 75px;
  font-family: Georgia, serif;
  line-height: 60px;
}

p.drop-caps.secondary:first-child:first-letter {
  margin-right: 10px;
  padding: 6px 12px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}

hr {
  display: block;
  clear: both;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  border: 0;
  background: #e8e8e8;
}

hr.sm {
  display: inline-block;
  margin: 15px 0;
  width: 40px;
  height: 2px;
  border: 0;
}

hr.md {
  display: inline-block;
  margin: 15px 0;
  width: 100px;
  height: 2px;
  border: 0;
}

hr.fw {
  display: block;
  clear: both;
  margin-top: 0;
  margin-bottom: 50px;
  width: 100%;
  height: 1px;
  border: 0;
}

ul.checks,
ul.angles,
ul.carets,
ul.chevrons {
  margin: 0;
  list-style-type: none;
  margin-bottom: 15px;
}

ul.checks>li,
ul.angles>li,
ul.carets>li,
ul.chevrons>li {
  margin: 10px 0;
}

ul.checks>li>i,
ul.angles>li>i,
ul.carets>li>i,
ul.chevrons>li>i {
  margin-right: 2px;
}

ul.inline {
  margin: 10px 0;
}

ul.inline>li {
  display: inline-block;
  margin-right: 10px;
}

ul.inline>li i.fa {
  margin-right: 2px;
}

ul.nav-list-primary>li a {
  display: block;
  padding: 8px;
  border-bottom: 1px solid #eceae4;
  color: #666666;
  text-decoration: none;
}

ul.nav-list-primary>li a:hover {
  text-decoration: none;
}

ul {
  margin: 10px 0 10px 20px;
  list-style-type: disc;
}

ol {
  margin: 10px 0 10px 30px;
  list-style-type: decimal;
}

ul ul,
ol ul {
  margin: 10px 0 10px 20px;
}

ul ul {
  list-style-type: circle;
}

dl {
  margin: 10px 0;
}

dl dt {
  font-weight: 700;
}

label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  margin-bottom: 20px;
}

.title {
  border-bottom: #e8e8e8 1px solid;
}

.titleb {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-family: "Roboto", sans-serif;
  font-display: swap;
}
a.title {
  font-style: italic;
}
.title .title-border {
  font-weight: bold;
  padding-bottom: 9px;
  display: inline-block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: -1px;
}

.label {
  font-family: "Roboto", sans-serif !important;
  font-display: swap;
}

/* ==================================================
   5. Spacers Sets
================================================== */
.spacer-20 {
  height: 30px;
  clear: both;
  width: 100%;
}

/* ==================================================
   5. Multi purpose Elements
================================================== */
.overlay-transparent,
.gallery-grid .gallery-cat {
  background: rgba(0, 0, 0, 0.6);
}

.next-prev-nav {
  display: inline-block;
}

.next-prev-nav a {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
}

.next-prev-nav a:hover {
  color: #fff;
}

.meta-data {
  color: #999;
  font-size: 11px;
  font-family: "Roboto", sans-serif;
  font-display: swap;
  text-transform: uppercase;
  font-weight: 700;
}

.meta-data sup {
  text-transform: none;
}

.meta-data a {
  color: #666666;
}

.meta-data a i {
  color: #999999;
}

.meta-data a:hover {
  text-decoration: none;
}

.meta-data>span {
  margin-right: 20px;
  display: inline-block;
}

.meta-data i {
  margin-right: 4px;
}

.meta-data>span:last-child {
  margin-right: 0;
}

.social-links {
  margin: 0;
  list-style-type: none;
}

.social-links li {
  display: inline-block;
  margin-right: 10px;
  font-size: 18px;
}

.icon-block {
  text-align: center;
}

.icon-block .icon {
  width: 120px;
  height: 120px;
  line-height: 135px;
  text-align: center;
  display: inline-block;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  color: #ffffff;
  margin-bottom: 25px;
}

.icon-block .icon>i {
  font-size: 3em;
}

.icon-block .icon>span {
  display: block;
}

.icon-block p {
  padding: 0 20px;
}

.secondary-bar .icon-block {
  text-align: left;
  display: inline-block;
}

.secondary-bar .icon-block .icon {
  width: 70px;
  height: 70px;
  line-height: 75px;
  margin-bottom: 0;
  margin-right: 20px;
}

.secondary-bar .icon-block .icon i {
  font-size: 2em;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

.entry .flexslider {
  margin-bottom: 40px;
}

.slider-wrapper {
  position: relative;
}

.double-border {
  position: relative;
  width: 100%;
  height: 3px;
}

.double-border>div {
  position: absolute;
  width: 50%;
  height: 3px;
  left: 0;
  top: 0;
  display: block;
}

.double-border>div:last-child {
  right: 0;
  left: auto;
}

.border-cols {
  margin: 30px 0;
  border-bottom: 1px solid #e8e8e8;
}

.border-cols:after,
.post-content:after,
.related-posts:after,
.border-cols:before,
.post-content:before,
.related-posts:before {
  display: table;
  content: " ";
}

.border-cols:after,
.post-content:after,
.related-posts:after {
  clear: both;
}

.border-cols>div {
  float: left;
  padding: 0 15px;
  border-right: 1px solid #e8e8e8;
}

.border-cols>div:first-child {
  padding-left: 0;
}

.border-cols>div:last-child {
  border: 0;
}

.cols2>div {
  width: 50%;
}

.cols3>div {
  width: 33.333333%;
}

.cols4>div {
  width: 25%;
}

.basic-link {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  display: inline-block;
}

.basic-link:hover {
  color: #333;
}

.basic-link.inverted {
  text-transform: uppercase;
  margin-top: 10px;
}

/* ==================================================
   6. Site Structure & Appearance
================================================== */
body {
  background-color: #ffffff;
  color: #666666;
  font-size: 100%;
  font-weight: 300;
  font-family: "Roboto Slab", serif;
  line-height: 23px;
  background-attachment: fixed;
}

.body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body.boxed {
  background-color: #666;
}

body.boxed .body {
  position: relative;
  margin: 0 auto;
  max-width: 1080px;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  flex: 1 0 auto;
}

@media (min-width: 1200px) {

  body.boxed .body .site-header,
  body.boxed .body .main-menu-wrapper {
    max-width: 100%;
    width: 1080px;
  }
}

.display-none {
  display: none;
}

.site-header {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 9;
  width: 1080px;
  margin: 0 auto;
  margin-left: -540px;
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

body.boxed .body .site-header {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.top-header {
  position: relative;
  z-index: 2;
  background-color: #292929;
  height: 37px;
}

.upcoming-event-bar {
  line-height: 37px;
}

.upcoming-event-bar h4 {
  line-height: 37px;
  float: left;
  margin-bottom: 0;
  color: #fff;
  font-size: 11px;
  opacity: 0.7;
  margin-right: 12px;
}

.counter {
  float: left;
  height: 37px;
  border-left: 1px solid #1e2326;
  border-right: 1px solid #1e2326;
}

.counter .timer-col {
  float: left;
  padding: 0 10px;
  border-left: 1px solid #1e2326;
  border-right: 1px solid #3d474c;
}

.counter .timer-col:first-child {
  border-left: 1px solid #3d474c;
}

.timer-col #days,
.timer-col #hours,
.timer-col #minutes,
.timer-col #seconds {
  display: inline-block;
  color: #ffffff;
  opacity: 0.8;
}

.timer-col #days {}

.timer-col #hours,
.timer-col #minutes,
.timer-col #seconds {}

.timer-col .timer-type {
  font-size: 12px;
}

.top-header .social-links {
  float: right;
}

.top-header .social-links li {
  line-height: 35px;
}

.top-header .social-links a {
  padding: 0 5px;
  display: block;
  color: #fff;
  opacity: 0.4;
  -webkit-transition: all 0.3s ease 0.2s;
  -moz-transition: all 0.3s ease 0.2s;
  -ms-transition: all 0.3s ease 0.2s;
  -o-transition: all 0.3s ease 0.2s;
  transition: all 0.3s ease 0.1s;
}

.top-header .social-links a:hover {
  opacity: 1;
}

.top-menu {
  float: right;
  margin: 0;
  list-style-type: none;
}

.top-menu li {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
}

.top-menu li a {
  padding: 0 20px;
  line-height: 37px;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease 0.2s;
  -moz-transition: all 0.3s ease 0.2s;
  -ms-transition: all 0.3s ease 0.2s;
  -o-transition: all 0.3s ease 0.2s;
  transition: all 0.3s ease 0.1s;
}

.top-menu li a:hover {
  text-decoration: none;
}

.lower-header {
  width: 100%;
  position: relative;
  z-index: 9;
  height: 75px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
}

body.boxed .body .lower-header {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.is-sticky .lower-header {
  background-color: rgba(255, 255, 255, 0.9);
  left: 50%;
  margin-left: -540px;
  z-index: 111;
  width: 1080px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

h1.logo {
  padding: 0;
  padding-top: 11px;
  margin: 0;
  width: 200px;
  float: left;
}

.for-navi .col-md-8 {
  position: inherit !important;
}

.for-navi.container {
  position: relative;
}

/* ==================================================
   6.1. Main Navigation
================================================== */
.main-navigation {
  width: 100%;
  display: block;
}

.main-navigation>ul {
  margin: 0;
  list-style-type: none;
  float: right;
}

.main-navigation>ul>li {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.07em;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.main-navigation>ul>li>a {
  display: block;
  color: #555555;
  padding: 0 12px;
  line-height: 75px;
}

.main-navigation>ul>li:last-child a {
  padding-right: 0;
}

.main-navigation>ul>li>a>i {
  color: #999999;
}

.main-navigation>ul>li>a:hover {
  text-decoration: none;
}

.main-navigation>ul>li ul {
  margin: 0;
  list-style-type: none;
  position: absolute;
  top: 75px;
  left: 30px;
  z-index: 999;
  display: none;
  min-width: 100%;
  height: auto;
  background: #e8e8e8;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  margin-left: 12px;
}

.main-navigation>ul>li ul:before {
  position: absolute;
  left: 50%;
  top: auto;
  margin: -10px 0px 0px -10px;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 5px;
  border-bottom-color: rgba(41, 41, 41, 0.9);
  content: " ";
  pointer-events: none;
}

.main-navigation>ul>li ul li ul:before {
  position: absolute;
  left: 0;
  top: auto;
  margin: 15px 0px 0px -10px;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 5px;
  border-right-color: rgba(41, 41, 41, 0.9);
  content: " ";
  pointer-events: none;
}

.main-navigation>ul>li:last-child ul:before {
  margin-left: 20px;
}

.main-navigation>ul>li:last-child ul li ul:before {
  margin-left: 20px;
}

.main-navigation>ul>li:hover ul {
  display: block;
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.main-navigation>ul>li>ul li {
  line-height: normal;
  font-size: 11px;
  position: relative;
}

.main-navigation>ul>li>ul li>a {
  display: block;
  color: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  white-space: nowrap;
  text-decoration: none;
}

.main-navigation>ul>li>ul>li a:hover {
  color: #fff;
}

.main-navigation>ul>li>ul>li>a:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.05);
}

.main-navigation>ul>li>ul li:last-child a {
  border-bottom: 0;
}

.main-navigation>ul>li>ul li ul {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin: 0;
  list-style-type: none;
  left: 100% !important;
  top: 0;
  min-width: inherit;
  margin-left: 11px;
  position: absolute;
}

.main-navigation>ul>li.megamenu {
  position: inherit !important;
  left: 0;
}

.main-navigation>ul>li.megamenu i {
  font-size: 100%;
}

.main-navigation>ul>li.megamenu>ul {
  margin: 0;
  list-style-type: none;
  width: 1080px;
  left: -20px !important;
}

.main-navigation .megamenu-container {
  padding: 25px;
  width: 100%;
  color: #fafafa;
}

.main-navigation .megamenu-container p {
  text-transform: none;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
}

.main-navigation .megamenu-container ul {
  margin: 0;
  list-style-type: none;
  display: block !important;
  color: #fafafa;
  left: 0 !important;
  box-shadow: none;
  opacity: 1 !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: transparent;
  position: relative !important;
  text-align: left !important;
  margin-left: 0;
}

.main-navigation .megamenu-container ul .meta-data {
  color: #fafafa;
}

.main-navigation>ul>li.megamenu>ul:before {
  position: absolute;
  left: 50%;
  top: auto;
  margin: -10px 0px 0px -10px;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 5px;
  border-bottom-color: rgba(41, 41, 41, 0.9);
  content: " ";
  pointer-events: none;
}

.main-navigation>ul>li.megamenu .meta-data {
  text-transform: none;
}

.main-navigation .megamenu-container .megamenu-sub-title {
  display: block;
  padding-bottom: 7px;
  margin-bottom: 12px;
  color: #fff;
  font-weight: 400;
  font-size: 17px;
  text-align: left !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.main-navigation .megamenu-container ul li a {
  display: block;
  padding: 7px 0;
  border-bottom: 0;
}

.main-navigation .megamenu-container ul li a i {
  display: none;
}

.main-navigation .megamenu-container ul.sub-menu li a:hover {
  text-decoration: none;
}

#menu-toggle {
  float: right;
  font-size: 20px;
  margin-top: 25px;
}

/* Added in v1.3 */
.header-v2 .site-header,
.header-v3 .site-header {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  left: 0;
  margin: 0;
  width: 100%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.header-v2 .is-sticky .lower-header,
.header-v3 .is-sticky .lower-header {
  width: 100%;
  left: 0;
  margin: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.search-module,
.cart-module {
  position: relative;
}

.search-module-trigger,
.cart-module-trigger {
  width: 30px;
  float: right;
  margin-left: 15px;
  margin-top: 25px;
  text-align: right;
}

.search-module-opened,
.cart-module-opened {
  display: none;
  position: absolute;
  z-index: 999;
  right: 0;
  top: 100%;
  padding: 15px;
  background: #e8e8e8;
  border: 1px solid #ccc;
  border-top: 0;
}

.cart-module-opened {
  padding: 0;
}

.cart-module-items {
  margin: 0;
  padding: 15px;
  list-style-type: none;
  font-family: "Roboto", sans-serif;
}

.cart-module-items>li>img {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  float: left;
  margin-right: 8px;
}

.cart-module-items>li>.cart-module-item-name {
  font-weight: bold;
  font-size: 13px;
}

.cart-module-items>li>.cart-module-item-quantity {
  display: block;
  font-size: 12px;
}

.cart-module-footer {
  display: block;
  background: #f9f9f9;
  padding: 5px 15px;
}

.cart-module-footer:before,
.cart-module-footer:after {
  content: " ";
  display: table;
}

.cart-module-footer:after {
  clear: both;
}

.cart-module-footer a.basic-link {
  float: left;
}

.cart-module-footer a.basic-link:last-child {
  float: right;
}

.full-width-menu {
  position: absolute;
  width: 100%;
}

body.boxed .is-sticky .full-width-menu {
  max-width: 1080px;
}

.full-width-menu .main-navigation>ul>li>a>i,
.full-width-menu .search-module-trigger,
.full-width-menu .cart-module-trigger {
  color: #fff !important;
}

.full-width-menu .main-navigation>ul>li>a {
  color: #fff;
}

.full-width-menu .main-navigation>ul>li>a:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

.full-width-menu .main-navigation>ul>li>a>i {
  color: #eee;
}

.full-width-menu .main-navigation>ul>li ul {
  background: #fff !important;
}

.full-width-menu .main-navigation>ul>li ul li {
  text-transform: none;
  font-weight: 400;
  font-size: 12px;
}

.full-width-menu .main-navigation>ul>li.megamenu>ul:before,
.full-width-menu .main-navigation>ul>li>ul:before {
  border-bottom-color: #fff !important;
}

.full-width-menu .main-navigation>ul>li ul li ul:before {
  border-right-color: #fff !important;
}

.full-width-menu .main-navigation>ul>li.megamenu>ul ul:before {
  display: none;
}

.full-width-menu .main-navigation>ul>li>ul li>a {
  border-bottom-color: #eee;
}

.full-width-menu .main-navigation>ul>li>ul>li a:hover {
  color: #666;
}

.full-width-menu .main-navigation .megamenu-container,
.full-width-menu .main-navigation .megamenu-container .megamenu-sub-title,
.full-width-menu .main-navigation .megamenu-container p,
.full-width-menu .main-navigation .megamenu-container ul,
.full-width-menu .main-navigation>ul>li>ul li>a {
  color: #999;
}

.full-width-menu .main-navigation .megamenu-container ul .meta-data {
  color: #ddd;
}

.full-width-menu .main-navigation .megamenu-container .megamenu-sub-title {
  border-bottom-color: #eee;
}

.full-width-menu>.container>.main-navigation>ul {
  float: none;
}

.full-width-menu .main-navigation>ul>li>a {
  line-height: 45px;
  margin: 0 10px;
}

.full-width-menu .main-navigation>ul>li ul {
  margin-left: 20px;
  top: 45px;
}

.full-width-menu .main-navigation>ul>li ul ul {
  margin-left: 12px;
  top: 0;
}

.full-width-menu .main-navigation .megamenu-container {
  width: 1040px;
  text-align: left;
}

.full-width-menu .main-navigation .megamenu-container ul {
  top: 0;
  margin-left: 0;
}

.header-v3 .search-module-trigger,
.header-v3 .cart-module-trigger {
  margin-top: 12px;
}

.header-v3 .lower-header .social-links {
  margin-top: 24px;
}

.header-v3 .lower-header {
  position: static !important;
  padding-bottom: 15px;
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eceae4;
  left: 50%;
  margin-left: -1.5px;
}

.timeline>li {
  margin-bottom: 20px;
  position: relative;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
}

.timeline>li>.timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #eceae4;
  border-radius: 5px;
  padding: 20px;
  padding-top: 10px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

.timeline>li>.timeline-panel:before {
  position: absolute;
  top: 29px;
  right: -10px;
  display: inline-block;
  border-top: 10px solid transparent;
  border-left: 10px solid #eceae4;
  border-right: 0 solid #eceae4;
  border-bottom: 10px solid transparent;
  content: " ";
}

.timeline>li>.timeline-panel:after {
  position: absolute;
  top: 30px;
  right: -9px;
  display: inline-block;
  border-top: 9px solid transparent;
  border-left: 9px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 9px solid transparent;
  content: " ";
}

.timeline>li>.timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  padding-top: 11px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;
  z-index: 10;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline>li>.timeline-badge span {
  font-size: 14px;
  font-family: "Roboto Condensed", sans-serif;
  display: block;
  line-height: 16px;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  border-left-width: 0;
  border-right-width: 10px;
  left: -10px;
  right: auto;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  border-left-width: 0;
  border-right-width: 9px;
  left: -9px;
  right: auto;
}

.timeline-title {
  margin-top: 8px;
  margin-bottom: 10px;
  color: inherit;
}

.timeline-title a {
  text-decoration: none;
}

.timeline-body>p:last-child,
.timeline-body>ul:last-child {
  margin-bottom: 0;
}

.timeline-body>p+p {
  margin-top: 5px;
}

.timeline-body .media-box {
  margin-bottom: 15px;
}

.events-timeline.timeline>li>.timeline-panel {
  padding: 0;
}

.events-timeline.timeline .timeline-body .media-box {
  margin-bottom: 0;
}

.events-timeline.timeline>li .timeline-heading {
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.events-timeline .info-cols li {
  border-bottom: 0;
  border-top: 1px solid #e8e8e8;
}

.events-timeline .info-cols li:last-child {
  border-right: 0;
}

.events-timeline .info-cols li a:hover {
  background: none;
}

.blog-full-width .meta-data {
  margin-top: 7px;
}

.blog-full-width .meta-data span {
  margin: 10px 0;
  display: block;
}

.blog-full-width .post .img-thumbnail {
  margin-bottom: 20px;
}

.blog-full-width .post-meta:before,
.blog-full-width .post-meta:after {
  content: " ";
  display: table;
}

.blog-full-width .post-list-item {
  border-bottom: 0;
}

.blog-full-width .post-meta:after {
  clear: both;
}

.blog-full-width .post-meta>span {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 9px;
}

.blog-full-width .img-thumbnail {
  margin-bottom: 20px;
}

.fluid-width-video-wrapper {
  /* give fit to box an aspect ratio */
  display: inline-block !important;
  /* let it be styled thusly */
  padding: 0 !important;
  /* get rid of pre-styling */
  margin: 0 !important;
  width: 100% !important;
  /* take up full width available */
  padding-top: 56.25% !important;
  /* give aspect ratio of 16:9; "720 / 1280 = 0.5625" */
  height: 0px !important;
  /* don't want it to expand beyond padding */
  position: relative !important;
  /* allow for absolute positioning of child elements */
}

.fluid-width-video-wrapper iframe {
  position: absolute !important;
  /* expand to fill */
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
}

.site-header {
  z-index: 999;
}

/* End Added in v1.3 */

.tp-limited,
.hero-slider {
  position: relative;
  z-index: 1;
}

.tp-limited ul {
  list-style-type: none;
  margin: 0;
}

.tp-limited ul li {
  opacity: 0;
}

.lead-block {
  background: url(../images/wave-high.png) repeat-x #fafafa;
  border-bottom: #e8e8e8 1px solid;
  padding: 50px 0;
}

.lead-block .nav-tabs-bar {
  background: #fff;
}

.lead-block .nav-tabs>li>a {
  background: none;
}

.content {
  padding: 40px 0 50px;
  flex: 1 0 auto;
}

.home .content {
  padding: 0;
}

.border-col {
  border-right: #e8e8e8 1px solid;
  padding-top: 40px;
  padding-bottom: 40px;
}

.top-bottom-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

.home .sidebar {
  padding-top: 40px;
  padding-bottom: 30px;
}

.posts-listing {
  margin: 0;
  list-style-type: none;
}

.home .latest-posts h3 .title-border {
  padding-bottom: 18px;
}

.latest-posts .toblog {
  margin-top: 15px;
}

.posts-listing .post-excerpt {
  margin-top: 15px;
}

.post-list-item {
  padding: 20px 0;
  border-bottom: #e8e8e8 1px solid;
}

.posts-listing .post-title {
  font-size: 18px;
  font-weight: 400;
  display: block;
  margin-bottom: 0;
}

.upcoming-events {
  margin: 0;
  list-style-type: none;
}

.upcoming-events>li,
.staff_widget>ul>li {
  padding-top: 12px;
  position: relative;
}

.upcoming-events .event-title {
  margin-bottom: 0;
}

.upcoming-events .event-thumb,
.staff_widget>ul>li .img-thumbnail {
  width: 75px;
  height: 75px;
  float: left;
  display: inline-block;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.upcoming-events .event-excerpt,
.staff_widget>ul>li .people-info {
  padding-left: 85px;
  min-height: 79px;
}

.upcoming-events .event-date {
  position: absolute;
  top: 16px;
  left: 4px;
  width: 67px;
  height: 67px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  font-weight: 700;
  opacity: 0;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease 0.2s;
  -moz-transition: all 0.3s ease 0.2s;
  -ms-transition: all 0.3s ease 0.2s;
  -o-transition: all 0.3s ease 0.2s;
  transition: all 0.3s ease 0.1s;
}

.upcoming-events .event-date .day {
  font-size: 24px;
  display: block;
  margin-top: 12px;
}

.upcoming-events .event-date .monthyear {
  font-size: 11px;
  display: block;
}

.upcoming-events li:hover .event-date {
  opacity: 0.9;
  -webkit-transition: opacity 0.3s 0.2s;
  -moz-transition: opacity 0.3s 0.2s;
  -ms-transition: opacity 0.3s 0.2s;
  -o-transition: opacity 0.3s 0.2s;
  transition: opacity 0.3s 0.2s;
}

.upcoming-events .event-cats a {
  color: #999;
}

.upcoming-events .event-location {
  font-size: 12px;
}

.upcoming-events-footer {
  text-align: center;
}

.upcoming-events-footer a {
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
  font-family: "Roboto", sans-serif;
}

.featured-projects {
  padding: 40px 0;
}

.featured-projects img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: url(desaturate.svg#greyscale);
}

.featured-projects h2 {
  color: #ffffff;
}

.featured-projects h2 small {
  color: #ffffff;
}

.featured-project-block {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 7px;
  margin-bottom: 10px;
}

.project-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 10px;
  position: absolute;
  bottom: 7px;
  left: 7px;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 11px;
}

.project-overlay .project-title {
  float: left;
  line-height: 16px;
  padding: 4px 0;
}

.project-overlay .project-cat {
  display: inline-block;
  float: right;
  padding: 0 10px;
  text-align: center;
  line-height: 24px;
  letter-spacing: 1px;
}

.our-partners {
  border-top: #e8e8e8 1px solid;
  padding: 40px 0;
  background-image: url(../images/wave-high.png);
  background-repeat: repeat-x;
  background-color: #fafafa;
}

.partner-logos {
  margin: 0;
  list-style-type: none;
}

.partner-logos li {
  display: inline-block;
  margin: 28px 26px 0;
}

.partner-logos li:last-child {
  margin-right: 0;
}

.site-footer {
  color: #8a8a8a;
  flex-shrink: 0;
}

.site-footer a {
  color: #ccc;
}

.site-footer a:hover {
  color: #fff;
}

.site-top-footer {
  background-image: url(../images/wave-high.png);
  background-repeat: repeat-x;
  background-color: #2f2f2f;
  padding: 30px 0;
}

.site-bottom-footer {
  background-color: #292929;
  border-top: 1px solid #252525;
  padding: 20px 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.site-bottom-footer p {
  margin-bottom: 0;
  color: #666;
}

.footer-nav {
  float: right;
  margin: 0;
  list-style-type: none;
}

.footer-nav li {
  display: inline-block;
  text-transform: uppercase;
  margin-left: 15px;
}

/* Inner Pages */
.page-header {
  height: 230px;
  margin: 0;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: 0;
  flex-shrink: 0;
}

.page-header h2 {
  text-align: right;
  padding-top: 155px;
  color: #ffffff;
}

.page-header .breadcrumb {
  padding: 0;
  margin: 0;
  padding-top: 165px;
  background: none;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}

.secondary-bar {
  padding: 20px 0;
  background: url(../images/wave-high.png) repeat-x #fafafa;
  border-bottom: #e8e8e8 1px solid;
}

.secondary-bar .flexslider .flex-prev,
.secondary-bar .flexslider .flex-next {
  top: -44px;
}

.passed-events.angles li {
  float: left;
  width: 50%;
  padding-right: 20px;
  margin: 0;
  margin-bottom: 8px;
}

listing-header {
  position: relative;
}

.listing-header h2 .label {
  position: relative;
  top: -5px;
}

.listing-header h3 {
  margin-bottom: 0;
}

.listing-content {}

.events-listing .upcoming-events li {
  background-color: #fff;
  padding-left: 10px;
  padding-bottom: 6px;
}

.events-listing .upcoming-events li .event-date {
  left: 14px;
}

.events-listing .upcoming-events li:nth-child(2n + 1) {
  background-color: #fafafa;
}

.events-listing .event-details-btn {
  float: right;
  margin-left: 10px;
  position: relative;
  top: -11px;
  line-height: 79px;
  margin-bottom: -11px;
  display: block;
  width: 60px;
  height: auto;
  background-color: #e8e8e8;
  text-align: center;
  color: #666;
  font-size: 24px;
}

.events-listing .event-details-btn:hover {
  color: #fff;
}

.fc-event {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.grid-holder {
  margin: 0;
  list-style-type: none;
  width: 105%;
  margin-left: -3%;
  float: left;
}

.grid-holder .grid-item {
  float: left;
}

.grid-item {
  margin-bottom: 3%;
  border-bottom: medium none;
}

.grid-item-inner {
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  background: #ffffff;
}

.grid-holder .grid-item {
  margin-left: 3%;
}

.grid-holder.col-2 .grid-item {
  width: 47%;
}

.grid-holder.col-3 .grid-item {
  width: 29.63%;
}

.grid-holder.col-4 .grid-item {
  width: 22%;
}

.grid-media {
  position: relative;
}

.info-cols {
  margin: 0;
  list-style-type: none;
  width: 100%;
}

.info-cols li {
  width: 25%;
  text-align: center;
  float: left;
  height: 40px;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.info-cols li a {
  width: 100%;
  padding: 10px;
  display: block;
  text-align: center;
  font-size: 15px;
}

.info-cols li a:hover {
  background-color: #fafafa;
}

.grid-content {
  padding: 15px 20px 1px 20px;
}

.grid-content .fa-external-link {
  margin-left: 10px;
}

.grid-item h3 {
  line-height: 1.2em;
}

.single-event-info {}

.single-event-info .day {
  display: block;
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #333;
}

.single-event-info .day .label {
  position: relative;
  top: -8px;
}

.single-event-info .date {
  display: block;
  margin-top: 7px;
  letter-spacing: 2px;
}

.single-event-info .time {
  color: #fff;
  padding: 3px 10px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.event-single-venue {}

.event-single-venue>span:first-child {
  float: left;
  width: 60px;
  height: 60px;
  line-height: 58px;
  margin-right: 10px;
  font-size: 3em;
  color: #ffffff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
}

.event-single-venue>span {
  display: block;
}

.event-register-block {
  display: block;
  padding: 12px;
  background-color: #ffffff;
  border-style: dashed;
  border-width: 1px;
  text-align: center;
  line-height: 45px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.event-register-block:hover {
  color: #fff;
}

#featured-events ul.slides {
  background: #fff;
  padding: 0 0 7px 10px;
  border-top: 2px solid transparent;
}

.entry .tabs {
  margin-top: 40px;
}

#solutions>div {
  margin-bottom: 30px;
}

#help {
  margin-top: -20px;
}

.projects-grid .project-cat,
.gallery-grid .gallery-cat {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
  padding: 2px 12px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
}

.posts-grid h3.post-title {
  margin-bottom: 5px;
}

.posts-grid .meta-data {
  margin-bottom: 12px;
}

.single-post .meta-data,
.post-single-image {
  margin-bottom: 20px;
}

.related-posts {
  margin: 20px 0;
}

.related-post h3 {
  margin-bottom: 0;
  margin-top: 10px;
}

#about-join .icon-block {
  margin-top: 40px;
}

.staff-item {
  margin: 20px 0 30px;
}

.staff-item h3 {
  margin-bottom: 0;
}

.staff-item .meta-data {
  margin-bottom: 10px;
  display: block;
}

.staff-volunteers {
  padding: 25px;
  color: #fff;
  margin-bottom: 20px;
}

.custom-donate-amount {
  display: none;
}

#message {
  margin-top: 20px;
}

.post-comments {
  margin: 40px 0 20px;
}

.comments {
  margin: 0;
  list-style-type: none;
}

.comments li {
  border-bottom: 1px solid #e8e8e8;
  padding-top: 30px;
  padding-bottom: 10px;
}

.comments li ul {
  border-top: 1px solid #e8e8e8;
}

.comments li .post-comment-block {}

.comments li.admin>.post-comment-block {}

.comments li .img-thumbnail {
  float: left;
  margin-right: 20px;
}

.comments li h5 {
  margin-bottom: 5px;
}

.comments li:last-child {
  border-bottom: 0;
}

.comments li ul {
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 0;
  margin-right: 0;
}

.comments li ul li {
  margin-left: 50px;
}

.comments li ul li:last-child {
  border-bottom: 0;
}

.comments .comment-text {
  margin-top: 20px;
}

.post-comment-form {
  margin: 30px 0 0 0;
  border-bottom: 0;
}

.post-comment-form .form-control {
  margin-bottom: 20px;
}

/* ==================================================
   6.2. Widgets Styling
================================================== */
.widget.sidebar-widget {
  margin-bottom: 30px;
}

.sidebar .widget.sidebar-widget:last-child {
  margin-bottom: 0;
}

.sidebar h3.title .title-border {
  padding-bottom: 18px;
}

.widget ul,
.widget ol {
  margin: 0;
  list-style-type: none;
}

.widget li {
  margin-bottom: 10px;
  padding-bottom: 7px;
}

.widget_categories ul li,
.widget_archive ul li,
.widget_recent_entries ul li,
.widget_recent_entries ul li,
.widget_recent_comments ul li,
.widget_links ul li,
.widget_meta ul li,
.upcoming_events_widget li,
.custom_categories_widget li {
  border-bottom: 1px solid #e8e8e8;
  text-align: right;
}

.widget_categories ul li a,
.widget_archive ul li a,
.widget_recent_entries ul li a,
.widget_recent_entries ul li a,
.widget_recent_comments ul li a,
.widget_links ul li a,
.widget_meta ul li a,
.custom_categories_widget li a {
  display: inline-block;
  text-align: left;
  float: left;
  position: relative;
}

.upcoming_events_widget li {
  text-align: left;
}

.staff_widget>ul>li {
  border-bottom: 1px solid #e8e8e8;
}

.staff_widget>ul>li .people-info .people-name {
  margin-bottom: 5px;
  margin-top: 12px;
}

.staff_widget>ul>li .people-info .people-position {
  font-size: 12px;
}

.tag-cloud a {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 7px;
  padding: 2px 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #fafafa;
  font-size: 12px;
  color: #ffffff;
  text-decoration: none;
}

.tag-cloud a:hover {
  color: #ffffff;
  text-decoration: none;
}

.footer-widget .widgettitle {
  color: #ccc;
  font-weight: 700;
  font-size: 11px;
}

.widget.instafeed_widget ul li,
.widget.flickr_widget ul li {
  width: 31%;
  margin-right: 2.33%;
  margin-top: 5px;
  margin-bottom: 2px;
  float: left;
  border-bottom: 0;
  padding: 0;
}

.flickr_widget_alt ul li {
  width: 48%;
  margin-right: 2%;
  margin-bottom: 20px;
  float: left;
}

.widget.instafeed_widget ul li a,
.widget.flickr_widget ul li a,
.widget.flickr_widget_alt ul li a,
.widget.recentposts_widget li a {
  display: inline;
}

.widget.instafeed_widget li:nth-child(3n + 3),
.widget.flickr_widget li:nth-child(3n + 3) {
  margin-right: 0;
}

.widget.flickr_widget_alt ul li {
  border-bottom: 0;
  padding: 0;
}

.flickr_widget_alt li:nth-child(2n + 2) {
  margin-right: 0;
}

.twitter-widget li:nth-child(odd) {
  display: none;
}

.twitter-widget li span.date {
  display: inline-block;
  color: #fff !important;
  padding: 1px 10px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font-size: 11px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  margin-top: 3px;
}

#back-to-top {
  position: fixed;
  right: 17px;
  bottom: 17px;
  display: none;
  padding: 5px 12px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
}

/* ==================================================
   6.3. Slider Revolution Custom Caption Styling
================================================== */
.tp-caption {
  visibility: hidden;
}

.tp-caption.h1,
.tp-caption.h2,
.tp-caption.h3,
.tp-caption.h4,
.tp-caption.h5,
.tp-caption.h6,
.tp-caption.para {
  color: #fff;
  display: block;
}

.number-block {
  width: 60px;
  height: 100px;
  text-align: center;
  color: #ffffff;
  line-height: 100px;
  font-size: 50px;
  font-weight: 700;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
}

.tp-caption.h1 {
  text-transform: uppercase;
  font-size: 72px;
  letter-spacing: 0;
  font-family: "Roboto", sans-serif;
}

.tp-caption.h2 {
  text-transform: uppercase;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: 0.1em;
  font-family: "Roboto", sans-serif;
}

.tp-caption.h4 {
  letter-spacing: 1em;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
}

.tp-caption.whiter .btn-default,
.featured-projects .btn-default {
  background: none;
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.tp-caption.whiter .btn-default:hover,
.featured-projects .btn-default:hover {
  background: #fff;
  color: #666;
}

.tp-caption.whiter hr.md {
  background: #fff !important;
}

/* ==================================================
   7. Responsive Media Queries
================================================== */
@media (min-width: 992px) and (max-width: 1199px) {
  .main-navigation>ul>li>a {
    padding: 0 8px;
  }

  .main-navigation>ul>li.megamenu>ul {
    width: 980px;
    left: -5px !important;
  }

  .site-header {
    width: 980px;
    margin-left: -490px;
  }

  .gallery-filter ul li span {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .page-header {
    height: 100px;
  }

  .page-header h2 {
    padding-top: 30px;
  }

  .page-header .breadcrumb {
    padding-top: 40px;
  }

  .site-header {
    width: 100%;
    z-index: 1000;
    position: relative;
    left: 0;
    margin-left: 0;
  }

  h1.logo {
    width: 90%;
  }

  .main-navigation {
    display: none;
    width: 100%;
    position: absolute;
    height: auto;
    left: 0;
    top: 75px;
    background: #fff;
    z-index: 99;
    border-top: 2px solid #fafafa;
  }

  /* Added in v1.3 */
  .header-v3 .main-navigation {
    top: 100%;
  }

  .full-width-menu .main-navigation>ul>li>a {
    color: #666;
    line-height: 25px;
  }

  .full-width-menu .main-navigation>ul>li>a>i {
    color: #999 !important;
  }

  .full-width-menu .main-navigation>ul>li>a:hover {
    color: #333 !important;
  }

  /* End Added in v1.3 */
  .main-navigation ul {
    float: none;
  }

  .main-navigation>ul>li {
    display: block;
    width: 100%;
  }

  .main-navigation>ul>li>a {
    display: block;
    padding: 15px 0 15px 20px;
    line-height: normal;
  }

  .main-navigation>ul>li ul {
    position: static;
    top: 0;
    left: 0 !important;
    margin: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  .main-navigation>ul>li>ul li ul {
    position: static;
    margin: 0;
    left: 0 !important;
    width: 100%;
  }

  .main-navigation>ul>li>ul:before,
  .main-navigation>ul>li>ul li ul:before {
    display: none;
  }

  .main-navigation>ul>li.megamenu>ul {
    width: 100%;
  }

  .main-navigation>ul>li.megamenu>ul .megamenu-container>div.row>div {
    margin-top: 20px;
  }

  .number-block {
    width: 40px;
    height: 80px;
  }

  .icon-block p {
    padding: 0;
  }

  .border-col {
    border-right: 0;
  }

  .post-thumb {
    margin-bottom: 20px;
  }

  .partner-logos li {
    margin: 28px 52px 0 0;
  }

  .sidebar {
    margin-top: 40px;
  }

  .secondary-bar .nav-pills {
    float: left !important;
  }

  .secondary-bar span.big,
  .secondary-bar .basic-link {
    display: block;
    margin-bottom: 20px;
    line-height: 1em;
  }

  .secondary-bar .btn.pull-right {
    float: left !important;
  }
}

@media only screen and (max-width: 767px) {

  /* Isotope */
  .isotope {
    overflow: visible !important;
    height: auto !important;
  }

  .isotope-item {
    position: static !important;
    margin-right: auto;
    margin-left: auto;
    width: auto;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
  }

  .isotope-hidden {
    display: none !important;
  }

  .grid-item {
    margin-bottom: 6%;
  }

  .grid-holder.col-2 .grid-item {
    width: 95%;
  }

  .grid-holder.col-3 .grid-item {
    width: 95%;
  }

  .grid-holder.col-4 .grid-item {
    width: 95%;
  }

  .img-thumbnail {
    margin-bottom: 20px;
  }

  .grid-item .media-box {
    margin-bottom: 0;
  }

  .sidebar {
    float: left;
    width: 100%;
    margin-top: 30px;
  }

  #back-to-top {
    display: none !important;
  }

  .number-block {
    width: 20px;
    height: 40px;
  }

  .icon-block {
    margin-bottom: 40px;
  }

  .footer-widget {
    margin-bottom: 30px;
  }

  .site-bottom-footer {
    text-align: center;
  }

  .footer-nav {
    float: none;
    margin-top: 20px;
  }

  .footer-nav li {
    margin-left: 0;
    margin-right: 15px;
  }

  .secondary-bar .col-md-offset-1 {
    margin-top: 20px;
  }

  .event-register-block {
    margin-top: 30px;
  }

  .border-cols>div {
    width: 100%;
    border-right: 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
  }

  .nav-tabs-bar {
    height: auto;
    padding-right: 0;
  }

  .nav-tabs-bar .pull-right {
    display: none;
  }

  .nav-tabs {
    float: none;
    width: 100%;
  }

  .nav-tabs>li {
    width: 100%;
  }

  .nav-tabs>li a {
    border-right: 0;
  }

  .featured-project-block {
    margin-bottom: 30px;
  }

  .event-single-venue>span:first-child {
    display: none;
  }

  .partner-logos li {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  /* Added in v1.3 */
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline>li>.timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline>li>.timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline>li>.timeline-panel {
    float: right;
  }

  ul.timeline>li>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline>li>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }

  /* End Added in v1.3 */
}

@media only screen and (min-width: 320px) and (max-width: 480px) {}

@media all and (-webkit-min-device-pixel-ratio: 1.5) {}

/* Simple approach */
.fallback .banner,
.fallback .page-header {
  background-image: url("../images/WildflowersMedium.jpg");
}

.webp .banner,
.webp .page-header {
  background-image: url("../images/WildflowersMedium.webp");
}

.avif .banner,
.avif .page-header {
  background-image: url("../images/WildflowersMedium.avif");
}

.banner {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 565px;
  opacity: 1;
  position: relative;
  background-image: image-set(url("../images/WildflowersMedium.avif") type("image/avif"),
      url("../images/WildflowersMedium.webp") type("image/webp"),
      url("../images/WildflowersMedium.jpg") type("image/jpeg"));

  background-size: cover;
  background-position: center center;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner * {
  width: 100%;
  margin: 0;
}

.banner h2 {
  color: white;
  position: relative;
  min-height: 0px;
  min-width: 0px;
  line-height: 55px;
  border-width: 0px;
  padding: 0px;
  letter-spacing: 4px;
  font-size: 40px;
  visibility: visible;
  transform: translate3d(0px, 0px, 0px);
  font-family: Roboto, sans-serif;
  font-display: swap;
  text-transform: uppercase;
  animation: fadein 1s;
  vertical-align: middle;
  justify-content: center;
}

.banner .fadeIn {
  width: 100px;
  position: relative;
  animation: fadein 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  margin-top: 50px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.banner .fadeIn .btn-default {
  background: none;
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-display: swap;
}

.banner .fadeIn .btn-default:hover {
  background: #fff;
  color: #666;
}

.hamburger {
  position: relative;
  display: inline-block;
  width: 1.25em;
  height: 0.8em;
  margin-right: 0.3em;
  border-top: 0.2em solid #000;
  border-bottom: 0.2em solid #000;
  border-radius: 0.1em;
}

.hamburger:before {
  content: "";
  position: absolute;
  top: 0.1em;
  left: 0px;
  width: 100%;
  border-top: 0.2em solid #000;
  border-radius: 0.1em;
}

.main {
  flex: 1 0 auto;
}

.tab {
  margin-left: 40px;
}

@media only screen and (min-width: 922px) {

  .lower-header,
  .full-width-menu {
    position: fixed;
    top: 0;
    width: 1080px;
  }
}

ul.no-bullets {
  list-style-type: none;
  /* Remove bullets */
  padding: 0;
  /* Remove padding */
  margin: 0;
  /* Remove margins */
  vertical-align: middle;
}

/** LIGHTBOX MARKUP **/

.lightbox {
  /* Default to hidden */
  display: none;

  /* Overlay entire screen */
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* A bit of padding around image */
  padding: 1em;

  /* Translucent background */
  background: rgba(0, 0, 0, 0.8);
}

/* Unhide the lightbox when it's the target */
.lightbox:target {
  display: block;
}

.lightbox span {
  /* Full width and height */
  display: block;
  width: 100%;
  height: 100%;

  /* Size and position background image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}