@import "https://fonts.googleapis.com/css?family=Roboto:400,400italic,700,700italic";
@import "https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700";
.appear-animation {
  opacity: 0;
}

.appear-animation-visible {
  opacity: 1;
}

.animated, .appear-animation {
  -ms-animation-fill-mode: both;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@-webkit-keyframes shake {
  0%, 100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
  }
}

@-moz-keyframes shake {
  0%, 100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -moz-transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -moz-transform: translateX(10px);
  }
}

@-o-keyframes shake {
  0%, 100% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -o-transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -o-transform: translateX(10px);
  }
}

@keyframes shake {
  0%, 100% {
    opacity: 1;
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.shake {
  animation-name: shake;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    opacity: 1;
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation-name: bounce;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }

  10%, 20% {
    -webkit-transform: scale(.9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale(1.1)rotate(-3deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1)rotate(0);
  }
}

@-moz-keyframes tada {
  0% {
    -moz-transform: scale(1);
  }

  10%, 20% {
    -moz-transform: scale(.9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -moz-transform: scale(1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    -moz-transform: scale(1.1)rotate(-3deg);
  }

  100% {
    opacity: 1;
    -moz-transform: scale(1)rotate(0);
  }
}

@-o-keyframes tada {
  0% {
    -o-transform: scale(1);
  }

  10%, 20% {
    -o-transform: scale(.9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -o-transform: scale(1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    -o-transform: scale(1.1)rotate(-3deg);
  }

  100% {
    opacity: 1;
    -o-transform: scale(1)rotate(0);
  }
}

@keyframes tada {
  0% {
    transform: scale(1);
  }

  10%, 20% {
    transform: scale(.9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale(1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    transform: scale(1.1)rotate(-3deg);
  }

  100% {
    opacity: 1;
    transform: scale(1)rotate(0);
  }
}

.tada {
  animation-name: tada;
}

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top;
  }

  20% {
    -webkit-transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes swing {
  20% {
    -moz-transform: rotate(15deg);
  }

  40% {
    -moz-transform: rotate(-10deg);
  }

  60% {
    -moz-transform: rotate(5deg);
  }

  80% {
    -moz-transform: rotate(-5deg);
  }

  100% {
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes swing {
  20% {
    -o-transform: rotate(15deg);
  }

  40% {
    -o-transform: rotate(-10deg);
  }

  60% {
    -o-transform: rotate(5deg);
  }

  80% {
    -o-transform: rotate(-5deg);
  }

  100% {
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    opacity: 1;
    transform: rotate(0);
  }
}

.swing {
  transform-origin: top;
  animation-name: swing;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%)rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%)rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes wobble {
  0% {
    -moz-transform: translateX(0%);
  }

  15% {
    -moz-transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    -moz-transform: translateX(20%)rotate(3deg);
  }

  45% {
    -moz-transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    -moz-transform: translateX(10%)rotate(2deg);
  }

  75% {
    -moz-transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}

@-o-keyframes wobble {
  0% {
    -o-transform: translateX(0%);
  }

  15% {
    -o-transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    -o-transform: translateX(20%)rotate(3deg);
  }

  45% {
    -o-transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    -o-transform: translateX(10%)rotate(2deg);
  }

  75% {
    -o-transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    transform: translateX(20%)rotate(3deg);
  }

  45% {
    transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    transform: translateX(10%)rotate(2deg);
  }

  75% {
    transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.wobble {
  animation-name: wobble;
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
  }

  10% {
    -webkit-transform: skewX(-8deg);
  }

  20% {
    -webkit-transform: skewX(7deg);
  }

  30% {
    -webkit-transform: skewX(-6deg);
  }

  40% {
    -webkit-transform: skewX(5deg);
  }

  50% {
    -webkit-transform: skewX(-4deg);
  }

  60% {
    -webkit-transform: skewX(3deg);
  }

  70% {
    -webkit-transform: skewX(-2deg);
  }

  80% {
    -webkit-transform: skewX(1deg);
  }

  90% {
    -webkit-transform: skewX(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: skewX(0);
  }
}

@-moz-keyframes wiggle {
  0% {
    -moz-transform: skewX(9deg);
  }

  10% {
    -moz-transform: skewX(-8deg);
  }

  20% {
    -moz-transform: skewX(7deg);
  }

  30% {
    -moz-transform: skewX(-6deg);
  }

  40% {
    -moz-transform: skewX(5deg);
  }

  50% {
    -moz-transform: skewX(-4deg);
  }

  60% {
    -moz-transform: skewX(3deg);
  }

  70% {
    -moz-transform: skewX(-2deg);
  }

  80% {
    -moz-transform: skewX(1deg);
  }

  90% {
    -moz-transform: skewX(0);
  }

  100% {
    opacity: 1;
    -moz-transform: skewX(0);
  }
}

@-o-keyframes wiggle {
  0% {
    -o-transform: skewX(9deg);
  }

  10% {
    -o-transform: skewX(-8deg);
  }

  20% {
    -o-transform: skewX(7deg);
  }

  30% {
    -o-transform: skewX(-6deg);
  }

  40% {
    -o-transform: skewX(5deg);
  }

  50% {
    -o-transform: skewX(-4deg);
  }

  60% {
    -o-transform: skewX(3deg);
  }

  70% {
    -o-transform: skewX(-2deg);
  }

  80% {
    -o-transform: skewX(1deg);
  }

  90% {
    -o-transform: skewX(0);
  }

  100% {
    opacity: 1;
    -o-transform: skewX(0);
  }
}

@keyframes wiggle {
  0% {
    transform: skewX(9deg);
  }

  10% {
    transform: skewX(-8deg);
  }

  20% {
    transform: skewX(7deg);
  }

  30% {
    transform: skewX(-6deg);
  }

  40% {
    transform: skewX(5deg);
  }

  50% {
    transform: skewX(-4deg);
  }

  60% {
    transform: skewX(3deg);
  }

  70% {
    transform: skewX(-2deg);
  }

  80% {
    transform: skewX(1deg);
  }

  90% {
    transform: skewX(0);
  }

  100% {
    opacity: 1;
    transform: skewX(0);
  }
}

.wiggle {
  animation-name: wiggle;
  animation-timing-function: ease-in;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.1);
  }

  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}

@-o-keyframes pulse {
  0% {
    -o-transform: scale(1);
  }

  50% {
    -o-transform: scale(1.1);
  }

  100% {
    opacity: 1;
    -o-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(.3);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }

  70% {
    -webkit-transform: scale(.9);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(.3);
  }

  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }

  70% {
    -moz-transform: scale(.9);
  }

  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(.3);
  }

  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }

  70% {
    -o-transform: scale(.9);
  }

  100% {
    opacity: 1;
    -o-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.bounceIn {
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }

  80% {
    -moz-transform: translateY(10px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }

  80% {
    -o-transform: translateY(10px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }

  80% {
    -webkit-transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }

  80% {
    -moz-transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }

  80% {
    -o-transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
  }

  80% {
    -webkit-transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(30px);
  }

  80% {
    -moz-transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateX(30px);
  }

  80% {
    -o-transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
  }

  80% {
    -webkit-transform: translateX(10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-30px);
  }

  80% {
    -moz-transform: translateX(10px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateX(-30px);
  }

  80% {
    -o-transform: translateX(10px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    opacity: 0;
    -webkit-transform: rotate(-200deg);
  }

  100% {
    -webkit-transform-origin: center;
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotateIn {
  0% {
    -moz-transform-origin: center;
    opacity: 0;
    -moz-transform: rotate(-200deg);
  }

  100% {
    -moz-transform-origin: center;
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes rotateIn {
  0% {
    -o-transform-origin: center;
    opacity: 0;
    -o-transform: rotate(-200deg);
  }

  100% {
    -o-transform-origin: center;
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes rotateIn {
  0% {
    transform-origin: center;
    opacity: 0;
    transform: rotate(-200deg);
  }

  100% {
    transform-origin: center;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: 0 100%;
    opacity: 0;
    -webkit-transform: rotate(90deg);
  }

  100% {
    -webkit-transform-origin: 0 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotateInUpLeft {
  0% {
    -moz-transform-origin: 0 100%;
    opacity: 0;
    -moz-transform: rotate(90deg);
  }

  100% {
    -moz-transform-origin: 0 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes rotateInUpLeft {
  0% {
    -o-transform-origin: 0 100%;
    opacity: 0;
    -o-transform: rotate(90deg);
  }

  100% {
    -o-transform-origin: 0 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(90deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: 0 100%;
    opacity: 0;
    -webkit-transform: rotate(-90deg);
  }

  100% {
    -webkit-transform-origin: 0 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotateInDownLeft {
  0% {
    -moz-transform-origin: 0 100%;
    opacity: 0;
    -moz-transform: rotate(-90deg);
  }

  100% {
    -moz-transform-origin: 0 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes rotateInDownLeft {
  0% {
    -o-transform-origin: 0 100%;
    opacity: 0;
    -o-transform: rotate(-90deg);
  }

  100% {
    -o-transform-origin: 0 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: 100% 100%;
    opacity: 0;
    -webkit-transform: rotate(-90deg);
  }

  100% {
    -webkit-transform-origin: 100% 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotateInUpRight {
  0% {
    -moz-transform-origin: 100% 100%;
    opacity: 0;
    -moz-transform: rotate(-90deg);
  }

  100% {
    -moz-transform-origin: 100% 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes rotateInUpRight {
  0% {
    -o-transform-origin: 100% 100%;
    opacity: 0;
    -o-transform: rotate(-90deg);
  }

  100% {
    -o-transform-origin: 100% 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: 100% 100%;
    opacity: 0;
    -webkit-transform: rotate(90deg);
  }

  100% {
    -webkit-transform-origin: 100% 100%;
    opacity: 1;
    -webkit-transform: rotate(0);
  }
}

@-moz-keyframes rotateInDownRight {
  0% {
    -moz-transform-origin: 100% 100%;
    opacity: 0;
    -moz-transform: rotate(90deg);
  }

  100% {
    -moz-transform-origin: 100% 100%;
    opacity: 1;
    -moz-transform: rotate(0);
  }
}

@-o-keyframes rotateInDownRight {
  0% {
    -o-transform-origin: 100% 100%;
    opacity: 0;
    -o-transform: rotate(90deg);
  }

  100% {
    -o-transform-origin: 100% 100%;
    opacity: 1;
    -o-transform: rotate(0);
  }
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(90deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

* {
  box-sizing: border-box;
  outline: none;
}

iframe {
  border: 0;
}

html, body {
  height: 100%;
}

html, hmtl a {
  width: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 100%;
}

a {
  color: #555;
  cursor: pointer;
  -o-transition: all .3s ease .2s;
  -webkit-transition: all .3s .2s;
  -moz-transition: all .3s .2s;
  -ms-transition: all .3s .2s;
  text-decoration: none;
  transition: all .3s .1s;
}

a:hover {
  text-decoration: none;
}

a:hover, a:active, a:focus {
  color: #666;
  outline: 0;
}

a img {
  border: none;
}

a > img {
  vertical-align: middle;
}

.gray-bg {
  background: #e8e8e8;
}

.gray-text {
  color: #bababa;
}

.lgray-bg {
  background: #fafafa;
}

.dgray-bg {
  background: #333;
}

img, img, object, embed, audio, video {
  max-width: 100%;
}

img {
  height: auto;
}

strong, .strong {
  font-weight: 700;
}

p {
  margin: 0 0 20px;
}

i, em, .italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.align-left {
  float: left;
  margin: 0 25px 0 0 !important;
}

.align-right {
  float: right;
  margin: 0 0 0 25px !important;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.color-text {
  color: #fff;
}

.through {
  text-decoration: line-through;
}

.border-radius {
  border-radius: 100%;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  font-display: swap;
  margin: 0 0 18px;
  font-family: Roboto Slab, sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h2 small {
  letter-spacing: .1em;
  font-display: swap;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-size: 45%;
  display: block;
}

h3 {
  font-size: 18px;
}

h3.block-title {
  text-transform: uppercase;
  font-size: 16px;
}

h4 {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-display: swap;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

h1.short, h2.short, h3.short, h4.short, h5.short, h6.short, p.short {
  margin-bottom: 5px;
}

h1.spaced, h2.spaced, h3.spaced, h4.spaced, h5.spaced, h6.spaced {
  margin-top: 22px;
}

h1 a, h2 a, h3 a, h5 a, h6 a {
  color: #333;
  text-decoration: none;
}

.huge {
  font-size: 10em;
}

blockquote {
  border-left: 3px solid;
  margin: 30px 0;
  padding: 0 0 0 30px;
}

blockquote p {
  white-space: normal;
  margin-bottom: 0;
  font-size: 20px;
  font-style: italic;
}

blockquote cite {
  color: #fff;
}

blockquote cite:before {
  content: "- ";
}

p {
  margin: 0 0 18px;
}

p.md {
  font-size: 16px;
  line-height: 22px;
}

p.lg {
  font-size: 30px;
  line-height: 1.2em;
}

p.lead {
  font-display: swap;
  font-family: Roboto, sans-serif;
}

.big {
  font-size: 200%;
  line-height: 1.5em;
}

.thin {
  font-weight: 300;
}

p.drop-caps:first-child:first-letter {
  float: left;
  margin-top: 5px;
  margin-right: 5px;
  padding: 4px;
  font-family: Georgia, serif;
  font-size: 75px;
  line-height: 60px;
}

p.drop-caps.secondary:first-child:first-letter {
  color: #fff;
  border-radius: 4px;
  margin-right: 10px;
  padding: 6px 12px;
}

hr {
  clear: both;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  border: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}

hr.sm {
  width: 40px;
  height: 2px;
  border: 0;
  margin: 15px 0;
  display: inline-block;
}

hr.md {
  width: 100px;
  height: 2px;
  border: 0;
  margin: 15px 0;
  display: inline-block;
}

hr.fw {
  clear: both;
  width: 100%;
  height: 1px;
  border: 0;
  margin-top: 0;
  margin-bottom: 50px;
  display: block;
}

ul.checks, ul.angles, ul.carets, ul.chevrons {
  margin: 0 0 15px;
  list-style-type: none;
}

ul.checks > li, ul.angles > li, ul.carets > li, ul.chevrons > li {
  margin: 10px 0;
}

ul.checks > li > i, ul.angles > li > i, ul.carets > li > i, ul.chevrons > li > i {
  margin-right: 2px;
}

ul.inline {
  margin: 10px 0;
}

ul.inline > li {
  margin-right: 10px;
  display: inline-block;
}

ul.inline > li i.fa {
  margin-right: 2px;
}

ul.nav-list-primary > li a {
  color: #666;
  border-bottom: 1px solid #eceae4;
  padding: 8px;
  text-decoration: none;
  display: block;
}

ul.nav-list-primary > li a:hover {
  text-decoration: none;
}

ul {
  margin: 10px 0 10px 20px;
  list-style-type: disc;
}

ol {
  margin: 10px 0 10px 30px;
  list-style-type: decimal;
}

ul ul, ol ul {
  margin: 10px 0 10px 20px;
}

ul ul {
  list-style-type: circle;
}

dl {
  margin: 10px 0;
}

dl dt {
  font-weight: 700;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  display: inline-block;
}

.form-control {
  margin-bottom: 20px;
}

.title {
  border-bottom: 1px solid #e8e8e8;
}

.titleb {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-display: swap;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

a.title {
  font-style: italic;
}

.title .title-border {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: -1px;
  padding-bottom: 9px;
  font-weight: bold;
  display: inline-block;
}

.label {
  font-display: swap;
  font-family: Roboto, sans-serif !important;
}

.spacer-20 {
  height: 30px;
  clear: both;
  width: 100%;
}

.overlay-transparent, .gallery-grid .gallery-cat {
  background: #0009;
}

.next-prev-nav {
  display: inline-block;
}

.next-prev-nav a {
  width: 20px;
  height: 20px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
}

.next-prev-nav a:hover {
  color: #fff;
}

.meta-data {
  color: #999;
  font-display: swap;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 700;
}

.meta-data sup {
  text-transform: none;
}

.meta-data a {
  color: #666;
}

.meta-data a i {
  color: #999;
}

.meta-data a:hover {
  text-decoration: none;
}

.meta-data > span {
  margin-right: 20px;
  display: inline-block;
}

.meta-data i {
  margin-right: 4px;
}

.meta-data > span:last-child {
  margin-right: 0;
}

.social-links {
  margin: 0;
  list-style-type: none;
}

.social-links li {
  margin-right: 10px;
  font-size: 18px;
  display: inline-block;
}

.icon-block {
  text-align: center;
}

.icon-block .icon {
  width: 120px;
  height: 120px;
  text-align: center;
  color: #fff;
  border-radius: 100%;
  margin-bottom: 25px;
  line-height: 135px;
  display: inline-block;
}

.icon-block .icon > i {
  font-size: 3em;
}

.icon-block .icon > span {
  display: block;
}

.icon-block p {
  padding: 0 20px;
}

.secondary-bar .icon-block {
  text-align: left;
  display: inline-block;
}

.secondary-bar .icon-block .icon {
  width: 70px;
  height: 70px;
  margin-bottom: 0;
  margin-right: 20px;
  line-height: 75px;
}

.secondary-bar .icon-block .icon i {
  font-size: 2em;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

.entry .flexslider {
  margin-bottom: 40px;
}

.slider-wrapper {
  position: relative;
}

.double-border {
  width: 100%;
  height: 3px;
  position: relative;
}

.double-border > div {
  width: 50%;
  height: 3px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.double-border > div:last-child {
  left: auto;
  right: 0;
}

.border-cols {
  border-bottom: 1px solid #e8e8e8;
  margin: 30px 0;
}

.border-cols:after, .post-content:after, .related-posts:after, .border-cols:before, .post-content:before, .related-posts:before {
  content: " ";
  display: table;
}

.border-cols:after, .post-content:after, .related-posts:after {
  clear: both;
}

.border-cols > div {
  float: left;
  border-right: 1px solid #e8e8e8;
  padding: 0 15px;
}

.border-cols > div:first-child {
  padding-left: 0;
}

.border-cols > div:last-child {
  border: 0;
}

.cols2 > div {
  width: 50%;
}

.cols3 > div {
  width: 33.3333%;
}

.cols4 > div {
  width: 25%;
}

.basic-link {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  display: inline-block;
}

.basic-link:hover {
  color: #333;
}

.basic-link.inverted {
  text-transform: uppercase;
  margin-top: 10px;
}

body {
  color: #666;
  background-color: #fff;
  background-attachment: fixed;
  font-family: Roboto Slab, serif;
  font-size: 100%;
  font-weight: 300;
  line-height: 23px;
}

.body {
  height: 100%;
  flex-direction: column;
  display: flex;
}

body.boxed {
  background-color: #666;
}

body.boxed .body {
  max-width: 1080px;
  width: 100%;
  height: auto;
  background-color: #fff;
  flex: 1 0 auto;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 2px #0003;
}

@media (min-width: 1200px) {
  body.boxed .body .site-header, body.boxed .body .main-menu-wrapper {
    max-width: 100%;
    width: 1080px;
  }
}

.display-none {
  display: none;
}

.site-header {
  z-index: 9;
  width: 1080px;
  border-radius: 0 0 5px 5px;
  margin: 0 auto 0 -540px;
  position: absolute;
  top: 0;
  left: 50%;
  box-shadow: 0 1px 2px #0000001a;
}

body.boxed .body .site-header {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.top-header {
  z-index: 2;
  height: 37px;
  background-color: #292929;
  position: relative;
}

.upcoming-event-bar {
  line-height: 37px;
}

.upcoming-event-bar h4 {
  float: left;
  color: #fff;
  opacity: .7;
  margin-bottom: 0;
  margin-right: 12px;
  font-size: 11px;
  line-height: 37px;
}

.counter {
  float: left;
  height: 37px;
  border-left: 1px solid #1e2326;
  border-right: 1px solid #1e2326;
}

.counter .timer-col {
  float: left;
  border-left: 1px solid #1e2326;
  border-right: 1px solid #3d474c;
  padding: 0 10px;
}

.counter .timer-col:first-child {
  border-left: 1px solid #3d474c;
}

.timer-col #days, .timer-col #hours, .timer-col #minutes, .timer-col #seconds {
  color: #fff;
  opacity: .8;
  display: inline-block;
}

.timer-col .timer-type {
  font-size: 12px;
}

.top-header .social-links {
  float: right;
}

.top-header .social-links li {
  line-height: 35px;
}

.top-header .social-links a {
  color: #fff;
  opacity: .4;
  -o-transition: all .3s ease .2s;
  -webkit-transition: all .3s .2s;
  -moz-transition: all .3s .2s;
  -ms-transition: all .3s .2s;
  padding: 0 5px;
  transition: all .3s .1s;
  display: block;
}

.top-header .social-links a:hover {
  opacity: 1;
}

.top-menu {
  float: right;
  margin: 0;
  list-style-type: none;
}

.top-menu li {
  letter-spacing: .04em;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}

.top-menu li a {
  color: #fffc;
  -o-transition: all .3s ease .2s;
  -webkit-transition: all .3s .2s;
  -moz-transition: all .3s .2s;
  -ms-transition: all .3s .2s;
  padding: 0 20px;
  line-height: 37px;
  transition: all .3s .1s;
  display: block;
}

.top-menu li a:hover {
  text-decoration: none;
}

.lower-header {
  width: 100%;
  z-index: 9;
  height: 75px;
  background-color: #ffffffe6;
  border-radius: 0 0 5px 5px;
  position: relative;
}

body.boxed .body .lower-header {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.is-sticky .lower-header {
  z-index: 111;
  width: 1080px;
  background-color: #ffffffe6;
  margin-left: -540px;
  left: 50%;
  box-shadow: 0 1px 2px #0000001a;
}

h1.logo {
  width: 200px;
  float: left;
  margin: 0;
  padding: 11px 0 0;
}

.for-navi .col-md-8 {
  position: inherit !important;
}

.for-navi.container {
  position: relative;
}

.main-navigation {
  width: 100%;
  display: block;
}

.main-navigation > ul {
  float: right;
  margin: 0;
  list-style-type: none;
}

.main-navigation > ul > li {
  letter-spacing: .07em;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  position: relative;
}

.main-navigation > ul > li > a {
  color: #555;
  padding: 0 12px;
  line-height: 75px;
  display: block;
}

.main-navigation > ul > li:last-child a {
  padding-right: 0;
}

.main-navigation > ul > li > a > i {
  color: #999;
}

.main-navigation > ul > li > a:hover {
  text-decoration: none;
}

.main-navigation > ul > li ul {
  z-index: 999;
  min-width: 100%;
  height: auto;
  background: #e8e8e8;
  border-radius: 0 0 4px 4px;
  margin: 0 0 0 12px;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 75px;
  left: 30px;
  box-shadow: 0 1px 2px #0003;
}

.main-navigation > ul > li ul:before {
  width: 0;
  height: 0;
  content: " ";
  pointer-events: none;
  border: 5px solid #0000;
  border-bottom-color: #292929e6;
  margin: -10px 0 0 -10px;
  position: absolute;
  top: auto;
  left: 50%;
}

.main-navigation > ul > li ul li ul:before {
  width: 0;
  height: 0;
  content: " ";
  pointer-events: none;
  border: 5px solid #0000;
  border-right-color: #292929e6;
  margin: 15px 0 0 -10px;
  position: absolute;
  top: auto;
  left: 0;
}

.main-navigation > ul > li:last-child ul:before, .main-navigation > ul > li:last-child ul li ul:before {
  margin-left: 20px;
}

.main-navigation > ul > li:hover ul {
  animation-name: fadeInUp;
  display: block;
}

.main-navigation > ul > li > ul li {
  font-size: 11px;
  line-height: normal;
  position: relative;
}

.main-navigation > ul > li > ul li > a {
  color: #fffc;
  white-space: nowrap;
  border-bottom: 1px solid #fff3;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}

.main-navigation > ul > li > ul > li a:hover {
  color: #fff;
}

.main-navigation > ul > li > ul > li > a:hover {
  background-color: #0000000d;
  text-decoration: none;
}

.main-navigation > ul > li > ul li:last-child a {
  border-bottom: 0;
}

.main-navigation > ul > li > ul li ul {
  min-width: inherit;
  border-radius: 4px;
  margin: 0 0 0 11px;
  list-style-type: none;
  position: absolute;
  top: 0;
  left: 100% !important;
}

.main-navigation > ul > li.megamenu {
  left: 0;
  position: inherit !important;
}

.main-navigation > ul > li.megamenu i {
  font-size: 100%;
}

.main-navigation > ul > li.megamenu > ul {
  width: 1080px;
  margin: 0;
  list-style-type: none;
  left: -20px !important;
}

.main-navigation .megamenu-container {
  width: 100%;
  color: #fafafa;
  padding: 25px;
}

.main-navigation .megamenu-container p {
  text-transform: none;
  color: #fffc;
  font-size: 13px;
  font-weight: normal;
}

.main-navigation .megamenu-container ul {
  color: #fafafa;
  box-shadow: none;
  background: none;
  border-radius: 0;
  margin: 0;
  list-style-type: none;
  opacity: 1 !important;
  text-align: left !important;
  display: block !important;
  position: relative !important;
  left: 0 !important;
}

.main-navigation .megamenu-container ul .meta-data {
  color: #fafafa;
}

.main-navigation > ul > li.megamenu > ul:before {
  width: 0;
  height: 0;
  content: " ";
  pointer-events: none;
  border: 5px solid #0000;
  border-bottom-color: #292929e6;
  margin: -10px 0 0 -10px;
  position: absolute;
  top: auto;
  left: 50%;
}

.main-navigation > ul > li.megamenu .meta-data {
  text-transform: none;
}

.main-navigation .megamenu-container .megamenu-sub-title {
  color: #fff;
  border-bottom: 1px solid #fff3;
  margin-bottom: 12px;
  padding-bottom: 7px;
  font-size: 17px;
  font-weight: 400;
  display: block;
  text-align: left !important;
}

.main-navigation .megamenu-container ul li a {
  border-bottom: 0;
  padding: 7px 0;
  display: block;
}

.main-navigation .megamenu-container ul li a i {
  display: none;
}

.main-navigation .megamenu-container ul.sub-menu li a:hover {
  text-decoration: none;
}

#menu-toggle {
  float: right;
  margin-top: 25px;
  font-size: 20px;
}

.header-v2 .site-header, .header-v3 .site-header {
  width: 100%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  position: relative;
  left: 0;
}

.header-v2 .is-sticky .lower-header, .header-v3 .is-sticky .lower-header {
  width: 100%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  left: 0;
}

.search-module, .cart-module {
  position: relative;
}

.search-module-trigger, .cart-module-trigger {
  width: 30px;
  float: right;
  text-align: right;
  margin-top: 25px;
  margin-left: 15px;
}

.search-module-opened, .cart-module-opened {
  z-index: 999;
  background: #e8e8e8;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 15px;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

.cart-module-opened {
  padding: 0;
}

.cart-module-items {
  margin: 0;
  padding: 15px;
  font-family: Roboto, sans-serif;
  list-style-type: none;
}

.cart-module-items > li > img {
  width: 50px;
  height: 50px;
  float: left;
  border: 1px solid #ccc;
  margin-right: 8px;
}

.cart-module-items > li > .cart-module-item-name {
  font-size: 13px;
  font-weight: bold;
}

.cart-module-items > li > .cart-module-item-quantity {
  font-size: 12px;
  display: block;
}

.cart-module-footer {
  background: #f9f9f9;
  padding: 5px 15px;
  display: block;
}

.cart-module-footer:before, .cart-module-footer:after {
  content: " ";
  display: table;
}

.cart-module-footer:after {
  clear: both;
}

.cart-module-footer a.basic-link {
  float: left;
}

.cart-module-footer a.basic-link:last-child {
  float: right;
}

.full-width-menu {
  width: 100%;
  position: absolute;
}

body.boxed .is-sticky .full-width-menu {
  max-width: 1080px;
}

.full-width-menu .main-navigation > ul > li > a > i, .full-width-menu .search-module-trigger, .full-width-menu .cart-module-trigger {
  color: #fff !important;
}

.full-width-menu .main-navigation > ul > li > a {
  color: #fff;
}

.full-width-menu .main-navigation > ul > li > a:hover {
  color: #fffc !important;
}

.full-width-menu .main-navigation > ul > li > a > i {
  color: #eee;
}

.full-width-menu .main-navigation > ul > li ul {
  background: #fff !important;
}

.full-width-menu .main-navigation > ul > li ul li {
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
}

.full-width-menu .main-navigation > ul > li.megamenu > ul:before, .full-width-menu .main-navigation > ul > li > ul:before {
  border-bottom-color: #fff !important;
}

.full-width-menu .main-navigation > ul > li ul li ul:before {
  border-right-color: #fff !important;
}

.full-width-menu .main-navigation > ul > li.megamenu > ul ul:before {
  display: none;
}

.full-width-menu .main-navigation > ul > li > ul li > a {
  border-bottom-color: #eee;
}

.full-width-menu .main-navigation > ul > li > ul > li a:hover {
  color: #666;
}

.full-width-menu .main-navigation .megamenu-container, .full-width-menu .main-navigation .megamenu-container .megamenu-sub-title, .full-width-menu .main-navigation .megamenu-container p, .full-width-menu .main-navigation .megamenu-container ul, .full-width-menu .main-navigation > ul > li > ul li > a {
  color: #999;
}

.full-width-menu .main-navigation .megamenu-container ul .meta-data {
  color: #ddd;
}

.full-width-menu .main-navigation .megamenu-container .megamenu-sub-title {
  border-bottom-color: #eee;
}

.full-width-menu > .container > .main-navigation > ul {
  float: none;
}

.full-width-menu .main-navigation > ul > li > a {
  margin: 0 10px;
  line-height: 45px;
}

.full-width-menu .main-navigation > ul > li ul {
  margin-left: 20px;
  top: 45px;
}

.full-width-menu .main-navigation > ul > li ul ul {
  margin-left: 12px;
  top: 0;
}

.full-width-menu .main-navigation .megamenu-container {
  width: 1040px;
  text-align: left;
}

.full-width-menu .main-navigation .megamenu-container ul {
  margin-left: 0;
  top: 0;
}

.header-v3 .search-module-trigger, .header-v3 .cart-module-trigger {
  margin-top: 12px;
}

.header-v3 .lower-header .social-links {
  margin-top: 24px;
}

.header-v3 .lower-header {
  padding-bottom: 15px;
  position: static !important;
}

.timeline {
  padding: 20px 0;
  list-style: none;
  position: relative;
}

.timeline:before {
  content: " ";
  width: 3px;
  background-color: #eceae4;
  margin-left: -1.5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before, .timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before, .timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #eceae4;
  border-radius: 5px;
  padding: 10px 20px 20px;
  position: relative;
  box-shadow: 0 1px 6px #0000000d;
}

.timeline > li > .timeline-panel:before {
  content: " ";
  border: 10px solid #0000;
  border-left-color: #eceae4;
  border-right: 0 solid #eceae4;
  display: inline-block;
  position: absolute;
  top: 29px;
  right: -10px;
}

.timeline > li > .timeline-panel:after {
  content: " ";
  border: 9px solid #0000;
  border-left-color: #fff;
  border-right: 0 solid #fff;
  display: inline-block;
  position: absolute;
  top: 30px;
  right: -9px;
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  z-index: 10;
  border-radius: 50%;
  margin-left: -25px;
  padding-top: 11px;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: 16px;
  left: 50%;
}

.timeline > li > .timeline-badge span {
  font-family: Roboto Condensed, sans-serif;
  font-size: 14px;
  line-height: 16px;
  display: block;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 10px;
  left: -10px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 9px;
  left: -9px;
  right: auto;
}

.timeline-title {
  color: inherit;
  margin-top: 8px;
  margin-bottom: 10px;
}

.timeline-title a {
  text-decoration: none;
}

.timeline-body > p:last-child, .timeline-body > ul:last-child {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.timeline-body .media-box {
  margin-bottom: 15px;
}

.events-timeline.timeline > li > .timeline-panel {
  padding: 0;
}

.events-timeline.timeline .timeline-body .media-box {
  margin-bottom: 0;
}

.events-timeline.timeline > li .timeline-heading {
  padding: 10px 20px;
}

.events-timeline .info-cols li {
  border-top: 1px solid #e8e8e8;
  border-bottom: 0;
}

.events-timeline .info-cols li:last-child {
  border-right: 0;
}

.events-timeline .info-cols li a:hover {
  background: none;
}

.blog-full-width .meta-data {
  margin-top: 7px;
}

.blog-full-width .meta-data span {
  margin: 10px 0;
  display: block;
}

.blog-full-width .post .img-thumbnail {
  margin-bottom: 20px;
}

.blog-full-width .post-meta:before, .blog-full-width .post-meta:after {
  content: " ";
  display: table;
}

.blog-full-width .post-list-item {
  border-bottom: 0;
}

.blog-full-width .post-meta:after {
  clear: both;
}

.blog-full-width .post-meta > span {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 9px;
}

.blog-full-width .img-thumbnail {
  margin-bottom: 20px;
}

.fluid-width-video-wrapper {
  width: 100% !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 56.25% 0 0 !important;
  display: inline-block !important;
  position: relative !important;
}

.fluid-width-video-wrapper iframe {
  position: absolute !important;
  inset: 0 !important;
}

.site-header {
  z-index: 999;
}

.tp-limited, .hero-slider {
  z-index: 1;
  position: relative;
}

.tp-limited ul {
  margin: 0;
  list-style-type: none;
}

.tp-limited ul li {
  opacity: 0;
}

.lead-block {
  background: #fafafa url("wave-high.e2e1339e.png") repeat-x;
  border-bottom: 1px solid #e8e8e8;
  padding: 50px 0;
}

.lead-block .nav-tabs-bar {
  background: #fff;
}

.lead-block .nav-tabs > li > a {
  background: none;
}

.content {
  flex: 1 0 auto;
  padding: 40px 0 50px;
}

.home .content {
  padding: 0;
}

.border-col {
  border-right: 1px solid #e8e8e8;
  padding-top: 40px;
  padding-bottom: 40px;
}

.top-bottom-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

.home .sidebar {
  padding-top: 40px;
  padding-bottom: 30px;
}

.posts-listing {
  margin: 0;
  list-style-type: none;
}

.home .latest-posts h3 .title-border {
  padding-bottom: 18px;
}

.latest-posts .toblog, .posts-listing .post-excerpt {
  margin-top: 15px;
}

.post-list-item {
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 0;
}

.posts-listing .post-title {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  display: block;
}

.upcoming-events {
  margin: 0;
  list-style-type: none;
}

.upcoming-events > li, .staff_widget > ul > li {
  padding-top: 12px;
  position: relative;
}

.upcoming-events .event-title {
  margin-bottom: 0;
}

.upcoming-events .event-thumb, .staff_widget > ul > li .img-thumbnail {
  width: 75px;
  height: 75px;
  float: left;
  border-radius: 100%;
  display: inline-block;
}

.upcoming-events .event-excerpt, .staff_widget > ul > li .people-info {
  min-height: 79px;
  padding-left: 85px;
}

.upcoming-events .event-date {
  width: 67px;
  height: 67px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  opacity: 0;
  -o-transition: all .3s ease .2s;
  -webkit-transition: all .3s .2s;
  -moz-transition: all .3s .2s;
  -ms-transition: all .3s .2s;
  border-radius: 100%;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
  transition: all .3s .1s;
  position: absolute;
  top: 16px;
  left: 4px;
}

.upcoming-events .event-date .day {
  margin-top: 12px;
  font-size: 24px;
  display: block;
}

.upcoming-events .event-date .monthyear {
  font-size: 11px;
  display: block;
}

.upcoming-events li:hover .event-date {
  opacity: .9;
  -o-transition: opacity .3s .2s;
  transition: opacity .3s .2s;
}

.upcoming-events .event-cats a {
  color: #999;
}

.upcoming-events .event-location {
  font-size: 12px;
}

.upcoming-events-footer {
  text-align: center;
}

.upcoming-events-footer a {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 700;
  display: block;
}

.featured-projects {
  padding: 40px 0;
}

.featured-projects img {
  -webkit-filter: grayscale();
  -moz-filter: grayscale(100%);
  filter: grayscale();
  filter: url("desaturate.a352109c.svg#greyscale");
}

.featured-projects h2, .featured-projects h2 small {
  color: #fff;
}

.featured-project-block {
  background: #fff;
  margin-bottom: 10px;
  padding: 7px;
  display: block;
  position: relative;
}

.project-overlay {
  color: #fff;
  text-transform: uppercase;
  background-color: #00000080;
  padding-left: 10px;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  position: absolute;
  bottom: 7px;
  left: 7px;
}

.project-overlay .project-title {
  float: left;
  padding: 4px 0;
  line-height: 16px;
}

.project-overlay .project-cat {
  float: right;
  text-align: center;
  letter-spacing: 1px;
  padding: 0 10px;
  line-height: 24px;
  display: inline-block;
}

.our-partners {
  background-color: #fafafa;
  background-image: url("wave-high.e2e1339e.png");
  background-repeat: repeat-x;
  border-top: 1px solid #e8e8e8;
  padding: 40px 0;
}

.partner-logos {
  margin: 0;
  list-style-type: none;
}

.partner-logos li {
  margin: 28px 26px 0;
  display: inline-block;
}

.partner-logos li:last-child {
  margin-right: 0;
}

.site-footer {
  color: #8a8a8a;
  flex-shrink: 0;
}

.site-footer a {
  color: #ccc;
}

.site-footer a:hover {
  color: #fff;
}

.site-top-footer {
  background-color: #2f2f2f;
  background-image: url("wave-high.e2e1339e.png");
  background-repeat: repeat-x;
  padding: 30px 0;
}

.site-bottom-footer {
  background-color: #292929;
  border-top: 1px solid #252525;
  padding: 20px 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.site-bottom-footer p {
  color: #666;
  margin-bottom: 0;
}

.footer-nav {
  float: right;
  margin: 0;
  list-style-type: none;
}

.footer-nav li {
  text-transform: uppercase;
  margin-left: 15px;
  display: inline-block;
}

.page-header {
  height: 230px;
  background-color: #ccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  flex-shrink: 0;
  margin: 0;
}

.page-header h2 {
  text-align: right;
  color: #fff;
  padding-top: 155px;
}

.page-header .breadcrumb {
  text-transform: uppercase;
  background: none;
  margin: 0;
  padding: 165px 0 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.secondary-bar {
  background: #fafafa url("wave-high.e2e1339e.png") repeat-x;
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 0;
}

.secondary-bar .flexslider .flex-prev, .secondary-bar .flexslider .flex-next {
  top: -44px;
}

.passed-events.angles li {
  float: left;
  width: 50%;
  margin: 0 0 8px;
  padding-right: 20px;
}

listing-header {
  position: relative;
}

.listing-header h2 .label {
  position: relative;
  top: -5px;
}

.listing-header h3 {
  margin-bottom: 0;
}

.events-listing .upcoming-events li {
  background-color: #fff;
  padding-bottom: 6px;
  padding-left: 10px;
}

.events-listing .upcoming-events li .event-date {
  left: 14px;
}

.events-listing .upcoming-events li:nth-child(2n+1) {
  background-color: #fafafa;
}

.events-listing .event-details-btn {
  float: right;
  width: 60px;
  height: auto;
  text-align: center;
  color: #666;
  background-color: #e8e8e8;
  margin-bottom: -11px;
  margin-left: 10px;
  font-size: 24px;
  line-height: 79px;
  display: block;
  position: relative;
  top: -11px;
}

.events-listing .event-details-btn:hover {
  color: #fff;
}

.fc-event {
  border: 1px solid #0000001a !important;
}

.grid-holder {
  width: 105%;
  float: left;
  margin: 0 0 0 -3%;
  list-style-type: none;
}

.grid-holder .grid-item {
  float: left;
}

.grid-item {
  border-bottom: none;
  margin-bottom: 3%;
}

.grid-item-inner {
  background: #fff;
  box-shadow: 0 1px 3px #0003;
}

.grid-holder .grid-item {
  margin-left: 3%;
}

.grid-holder.col-2 .grid-item {
  width: 47%;
}

.grid-holder.col-3 .grid-item {
  width: 29.63%;
}

.grid-holder.col-4 .grid-item {
  width: 22%;
}

.grid-media {
  position: relative;
}

.info-cols {
  width: 100%;
  margin: 0;
  list-style-type: none;
}

.info-cols li {
  width: 25%;
  text-align: center;
  float: left;
  height: 40px;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.info-cols li a {
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 15px;
  display: block;
}

.info-cols li a:hover {
  background-color: #fafafa;
}

.grid-content {
  padding: 15px 20px 1px;
}

.grid-content .fa-external-link {
  margin-left: 10px;
}

.grid-item h3 {
  line-height: 1.2em;
}

.single-event-info .day {
  text-transform: uppercase;
  color: #333;
  margin-bottom: 5px;
  font-family: Roboto, sans-serif;
  font-size: 36px;
  display: block;
}

.single-event-info .day .label {
  position: relative;
  top: -8px;
}

.single-event-info .date {
  letter-spacing: 2px;
  margin-top: 7px;
  display: block;
}

.single-event-info .time {
  color: #fff;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 3px 10px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 700;
}

.event-single-venue > span:first-child {
  float: left;
  width: 60px;
  height: 60px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  margin-right: 10px;
  font-size: 3em;
  line-height: 58px;
}

.event-single-venue > span {
  display: block;
}

.event-register-block {
  text-align: center;
  text-transform: uppercase;
  background-color: #fff;
  border-style: dashed;
  border-width: 1px;
  padding: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  line-height: 45px;
  display: block;
}

.event-register-block:hover {
  color: #fff;
}

#featured-events ul.slides {
  background: #fff;
  border-top: 2px solid #0000;
  padding: 0 0 7px 10px;
}

.entry .tabs {
  margin-top: 40px;
}

#solutions > div {
  margin-bottom: 30px;
}

#help {
  margin-top: -20px;
}

.projects-grid .project-cat, .gallery-grid .gallery-cat {
  z-index: 99;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 2px 12px;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.posts-grid h3.post-title {
  margin-bottom: 5px;
}

.posts-grid .meta-data {
  margin-bottom: 12px;
}

.single-post .meta-data, .post-single-image {
  margin-bottom: 20px;
}

.related-posts {
  margin: 20px 0;
}

.related-post h3 {
  margin-top: 10px;
  margin-bottom: 0;
}

#about-join .icon-block {
  margin-top: 40px;
}

.staff-item {
  margin: 20px 0 30px;
}

.staff-item h3 {
  margin-bottom: 0;
}

.staff-item .meta-data {
  margin-bottom: 10px;
  display: block;
}

.staff-volunteers {
  color: #fff;
  margin-bottom: 20px;
  padding: 25px;
}

.custom-donate-amount {
  display: none;
}

#message {
  margin-top: 20px;
}

.post-comments {
  margin: 40px 0 20px;
}

.comments {
  margin: 0;
  list-style-type: none;
}

.comments li {
  border-bottom: 1px solid #e8e8e8;
  padding-top: 30px;
  padding-bottom: 10px;
}

.comments li ul {
  border-top: 1px solid #e8e8e8;
}

.comments li .img-thumbnail {
  float: left;
  margin-right: 20px;
}

.comments li h5 {
  margin-bottom: 5px;
}

.comments li:last-child {
  border-bottom: 0;
}

.comments li ul {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  list-style-type: none;
}

.comments li ul li {
  margin-left: 50px;
}

.comments li ul li:last-child {
  border-bottom: 0;
}

.comments .comment-text {
  margin-top: 20px;
}

.post-comment-form {
  border-bottom: 0;
  margin: 30px 0 0;
}

.post-comment-form .form-control {
  margin-bottom: 20px;
}

.widget.sidebar-widget {
  margin-bottom: 30px;
}

.sidebar .widget.sidebar-widget:last-child {
  margin-bottom: 0;
}

.sidebar h3.title .title-border {
  padding-bottom: 18px;
}

.widget ul, .widget ol {
  margin: 0;
  list-style-type: none;
}

.widget li {
  margin-bottom: 10px;
  padding-bottom: 7px;
}

.widget_categories ul li, .widget_archive ul li, .widget_recent_entries ul li, .widget_recent_entries ul li, .widget_recent_comments ul li, .widget_links ul li, .widget_meta ul li, .upcoming_events_widget li, .custom_categories_widget li {
  text-align: right;
  border-bottom: 1px solid #e8e8e8;
}

.widget_categories ul li a, .widget_archive ul li a, .widget_recent_entries ul li a, .widget_recent_entries ul li a, .widget_recent_comments ul li a, .widget_links ul li a, .widget_meta ul li a, .custom_categories_widget li a {
  text-align: left;
  float: left;
  display: inline-block;
  position: relative;
}

.upcoming_events_widget li {
  text-align: left;
}

.staff_widget > ul > li {
  border-bottom: 1px solid #e8e8e8;
}

.staff_widget > ul > li .people-info .people-name {
  margin-top: 12px;
  margin-bottom: 5px;
}

.staff_widget > ul > li .people-info .people-position {
  font-size: 12px;
}

.tag-cloud a {
  color: #fff;
  background: #fafafa;
  border: 1px solid #0000001a;
  border-radius: 3px;
  margin-bottom: 7px;
  margin-right: 4px;
  padding: 2px 8px;
  font-size: 12px;
  text-decoration: none;
  display: inline-block;
}

.tag-cloud a:hover {
  color: #fff;
  text-decoration: none;
}

.footer-widget .widgettitle {
  color: #ccc;
  font-size: 11px;
  font-weight: 700;
}

.widget.instafeed_widget ul li, .widget.flickr_widget ul li {
  width: 31%;
  float: left;
  border-bottom: 0;
  margin-top: 5px;
  margin-bottom: 2px;
  margin-right: 2.33%;
  padding: 0;
}

.flickr_widget_alt ul li {
  width: 48%;
  float: left;
  margin-bottom: 20px;
  margin-right: 2%;
}

.widget.instafeed_widget ul li a, .widget.flickr_widget ul li a, .widget.flickr_widget_alt ul li a, .widget.recentposts_widget li a {
  display: inline;
}

.widget.instafeed_widget li:nth-child(3n+3), .widget.flickr_widget li:nth-child(3n+3) {
  margin-right: 0;
}

.widget.flickr_widget_alt ul li {
  border-bottom: 0;
  padding: 0;
}

.flickr_widget_alt li:nth-child(2n+2) {
  margin-right: 0;
}

.twitter-widget li:nth-child(2n+1) {
  display: none;
}

.twitter-widget li span.date {
  text-transform: uppercase;
  border-radius: 3px;
  margin-top: 3px;
  padding: 1px 10px;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  display: inline-block;
  color: #fff !important;
}

#back-to-top {
  color: #fff;
  background: #000000b3;
  border-radius: 3px;
  padding: 5px 12px;
  display: none;
  position: fixed;
  bottom: 17px;
  right: 17px;
}

.tp-caption {
  visibility: hidden;
}

.tp-caption.h1, .tp-caption.h2, .tp-caption.h3, .tp-caption.h4, .tp-caption.h5, .tp-caption.h6, .tp-caption.para {
  color: #fff;
  display: block;
}

.number-block {
  width: 60px;
  height: 100px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 100px;
}

.tp-caption.h1 {
  text-transform: uppercase;
  letter-spacing: 0;
  font-family: Roboto, sans-serif;
  font-size: 72px;
}

.tp-caption.h2 {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-family: Roboto, sans-serif;
  font-size: 40px;
  line-height: 55px;
}

.tp-caption.h4 {
  letter-spacing: 1em;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
}

.tp-caption.whiter .btn-default, .featured-projects .btn-default {
  color: #fff;
  background: none;
  border-width: 2px;
  border-color: #fff;
  font-family: Roboto, sans-serif;
}

.tp-caption.whiter .btn-default:hover, .featured-projects .btn-default:hover {
  color: #666;
  background: #fff;
}

.tp-caption.whiter hr.md {
  background: #fff !important;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-navigation > ul > li > a {
    padding: 0 8px;
  }

  .main-navigation > ul > li.megamenu > ul {
    width: 980px;
    left: -5px !important;
  }

  .site-header {
    width: 980px;
    margin-left: -490px;
  }

  .gallery-filter ul li span {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .page-header {
    height: 100px;
  }

  .page-header h2 {
    padding-top: 30px;
  }

  .page-header .breadcrumb {
    padding-top: 40px;
  }

  .site-header {
    width: 100%;
    z-index: 1000;
    margin-left: 0;
    position: relative;
    left: 0;
  }

  h1.logo {
    width: 90%;
  }

  .main-navigation {
    width: 100%;
    height: auto;
    z-index: 99;
    background: #fff;
    border-top: 2px solid #fafafa;
    display: none;
    position: absolute;
    top: 75px;
    left: 0;
  }

  .header-v3 .main-navigation {
    top: 100%;
  }

  .full-width-menu .main-navigation > ul > li > a {
    color: #666;
    line-height: 25px;
  }

  .full-width-menu .main-navigation > ul > li > a > i {
    color: #999 !important;
  }

  .full-width-menu .main-navigation > ul > li > a:hover {
    color: #333 !important;
  }

  .main-navigation ul {
    float: none;
  }

  .main-navigation > ul > li {
    width: 100%;
    display: block;
  }

  .main-navigation > ul > li > a {
    padding: 15px 0 15px 20px;
    line-height: normal;
    display: block;
  }

  .main-navigation > ul > li ul {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    position: static;
    top: 0;
    left: 0 !important;
  }

  .main-navigation > ul > li > ul li ul {
    width: 100%;
    margin: 0;
    position: static;
    left: 0 !important;
  }

  .main-navigation > ul > li > ul:before, .main-navigation > ul > li > ul li ul:before {
    display: none;
  }

  .main-navigation > ul > li.megamenu > ul {
    width: 100%;
  }

  .main-navigation > ul > li.megamenu > ul .megamenu-container > div.row > div {
    margin-top: 20px;
  }

  .number-block {
    width: 40px;
    height: 80px;
  }

  .icon-block p {
    padding: 0;
  }

  .border-col {
    border-right: 0;
  }

  .post-thumb {
    margin-bottom: 20px;
  }

  .partner-logos li {
    margin: 28px 52px 0 0;
  }

  .sidebar {
    margin-top: 40px;
  }

  .secondary-bar .nav-pills {
    float: left !important;
  }

  .secondary-bar span.big, .secondary-bar .basic-link {
    margin-bottom: 20px;
    line-height: 1em;
    display: block;
  }

  .secondary-bar .btn.pull-right {
    float: left !important;
  }
}

@media only screen and (max-width: 767px) {
  .isotope {
    height: auto !important;
    overflow: visible !important;
  }

  .isotope-item {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    position: static !important;
    transform: none !important;
  }

  .isotope-hidden {
    display: none !important;
  }

  .grid-item {
    margin-bottom: 6%;
  }

  .grid-holder.col-2 .grid-item, .grid-holder.col-3 .grid-item, .grid-holder.col-4 .grid-item {
    width: 95%;
  }

  .img-thumbnail {
    margin-bottom: 20px;
  }

  .grid-item .media-box {
    margin-bottom: 0;
  }

  .sidebar {
    float: left;
    width: 100%;
    margin-top: 30px;
  }

  #back-to-top {
    display: none !important;
  }

  .number-block {
    width: 20px;
    height: 40px;
  }

  .icon-block {
    margin-bottom: 40px;
  }

  .footer-widget {
    margin-bottom: 30px;
  }

  .site-bottom-footer {
    text-align: center;
  }

  .footer-nav {
    float: none;
    margin-top: 20px;
  }

  .footer-nav li {
    margin-left: 0;
    margin-right: 15px;
  }

  .secondary-bar .col-md-offset-1 {
    margin-top: 20px;
  }

  .event-register-block {
    margin-top: 30px;
  }

  .border-cols > div {
    width: 100%;
    border-right: 0;
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .nav-tabs-bar {
    height: auto;
    padding-right: 0;
  }

  .nav-tabs-bar .pull-right {
    display: none;
  }

  .nav-tabs {
    float: none;
    width: 100%;
  }

  .nav-tabs > li {
    width: 100%;
  }

  .nav-tabs > li a {
    border-right: 0;
  }

  .featured-project-block {
    margin-bottom: 30px;
  }

  .event-single-venue > span:first-child {
    display: none;
  }

  .partner-logos li {
    text-align: center;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
    margin-left: 0;
    top: 16px;
    left: 15px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

.fallback .banner, .fallback .page-header {
  background-image: url("WildflowersMedium.52da10d6.jpg");
}

.webp .banner, .webp .page-header {
  background-image: url("WildflowersMedium.e3ab275e.webp");
}

.avif .banner, .avif .page-header {
  background-image: url("WildflowersMedium.3e5f0e68.avif");
}

.banner {
  width: 100%;
  height: 565px;
  opacity: 1;
  background-color: #0000;
  background-image: -webkit-image-set(url("WildflowersMedium.3e5f0e68.avif") 1x type("image/avif"), url("WildflowersMedium.e3ab275e.webp") 1x type("image/webp"), url("WildflowersMedium.52da10d6.jpg") 1x type("image/jpeg"));
  background-image: image-set("WildflowersMedium.3e5f0e68.avif" 1x type("image/avif"), "WildflowersMedium.e3ab275e.webp" 1x type("image/webp"), "WildflowersMedium.52da10d6.jpg" 1x type("image/jpeg"));
  background-position: center;
  background-size: cover;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.banner * {
  width: 100%;
  margin: 0;
}

.banner h2 {
  color: #fff;
  min-height: 0;
  min-width: 0;
  letter-spacing: 4px;
  visibility: visible;
  font-display: swap;
  text-transform: uppercase;
  vertical-align: middle;
  border-width: 0;
  justify-content: center;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 40px;
  line-height: 55px;
  animation: 1s fadein;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.banner .fadeIn {
  width: 100px;
  opacity: 0;
  margin-top: 50px;
  animation: 1s 1s forwards fadein;
  position: relative;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.banner .fadeIn .btn-default {
  color: #fff;
  font-display: swap;
  background: none;
  border-width: 2px;
  border-color: #fff;
  font-family: Roboto, sans-serif;
}

.banner .fadeIn .btn-default:hover {
  color: #666;
  background: #fff;
}

.hamburger {
  width: 1.25em;
  height: .8em;
  border-top: .2em solid #000;
  border-bottom: .2em solid #000;
  border-radius: .1em;
  margin-right: .3em;
  display: inline-block;
  position: relative;
}

.hamburger:before {
  content: "";
  width: 100%;
  border-top: .2em solid #000;
  border-radius: .1em;
  position: absolute;
  top: .1em;
  left: 0;
}

.main {
  flex: 1 0 auto;
}

.tab {
  margin-left: 40px;
}

@media only screen and (min-width: 922px) {
  .lower-header, .full-width-menu {
    width: 1080px;
    position: fixed;
    top: 0;
  }
}

ul.no-bullets {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.lightbox {
  z-index: 999;
  background: #000c;
  padding: 1em;
  display: none;
  position: fixed;
  inset: 0;
}

.lightbox:target {
  display: block;
}

.lightbox span {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

/*# sourceMappingURL=index.1bfe6320.css.map */
